@import '../../variables.scss';


.modal-user {
    .dark-modal-content {
        background-color: $c-dark-green;
        color: $c-white;

        .btn-close {
            color: $c-white;
        }
        
        .label {
            color: $c-white !important;
        }

        .css-t3ipsp-control, .css-13cymwt-control {
            background-color: $c-dark-blue;
            color: white;

            .css-1dimb5e-singleValue, .css-1jqq78o-placeholder {
                color: $c-white;
            }
        }

        .css-1nmdiq5-menu {
            background-color: $c-dark-green;
        }

        .css-d7l1ni-option, .css-1p3m7a8-multiValue {
            background-color: $c-primary !important;

            .css-wsp0cs-MultiValueGeneric {
                color: $c-white;
            }
        }
    }
}


.modal-form-user {
    .modal-content {
        width: 620px !important;
    }
}

.modal-user {
    display: flex !important;
    align-items: center;
    
    .modal-dialog .modal-content .modal-header .modal-title {
        font-size: 20px;
    }

    .modal-content {
        width: 800px;
    }

    .table-uni {
        .table-actions {
            justify-content: center;
        }
    }

    .form-user {
        font-size: 14px;
        padding: 10px 25px 0px 25px;

        .card-user {
            width: 100%;
            border-radius: 10px;
            border: 1px solid #E0E0E0;
            box-shadow: 0px 8px 16px 0px rgba(73, 73, 73, 0.06);
            height: 60px;
            margin-bottom: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px;

            p {
                margin: 0px;
                font-size: 14px;
                font-weight: 600;
            }

            svg {
                color: $c-primary;
                width: 20px;
                height: 20px;
                margin-right: 15px;
            }
        }

        .label {
            color: $c-label;
            font-weight: 500;
            margin-bottom: 8px;
        }

        .profile-inputs {
            display: flex;
            flex-direction: column;
            align-items: baseline;
            margin-bottom: 20px;
            width: 100%;

            .form-check-input:checked {
                background-color: $c-primary;
                border-color: $c-primary;
            }

            .css-t3ipsp-control {
                width: 200px;
            }

            .css-13cymwt-control {
                width: 200px;
                border-radius: 8px;
                min-height: 40px;
                height: fit-content;
            }

            .css-tr4s17-option {
                background-color: $c-primary;
            }

            .css-t3ipsp-control, .css-t3ipsp-control:hover {
                border-color: $c-primary;
                box-shadow: 0 0 0 1px $c-primary;
            }

            .css-d7l1ni-option {
                background-color: $c-light-green;
            }
        }

        .customer-input {
            display: flex;
            flex-direction: column;
            align-items: baseline;
            width: 100%;
            margin-left: 10px;

            .select-customers {
                width: 320px;

                .css-13cymwt-control {
                    border-radius: 8px;
                    min-height: 40px;
                    height: fit-content;
                }

                .css-t3ipsp-control, .css-t3ipsp-control:hover {
                    border-color: $c-primary;
                    box-shadow: 0 0 0 1px $c-primary;
                }

                .css-d7l1ni-option {
                    background-color: $c-light-green;
                }
            }
        }

        .active-input {
            display: flex;
            justify-content: flex-end;
            padding-right: 60px;
            .form-check {
                width: 80px;
            }
        }

        .buttons-user-form {
            justify-content: space-between;
            margin-top: 30px;

            button {
                width: 250px;
                border-radius: 20px;
                font-weight: 600;
            }

            .btn-outline {
                color: $c-primary;
                border: 2px solid $c-primary;
            }
        }
    }
}