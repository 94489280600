@import '../../variables.scss';

.dark {
    .uni-input-file {
        .no-files, button {
            background-color: $c-dark-green;

            .permitted-files {
                color: $c-white;
            }
        }

        .no-files, .files {
            button:hover {
                background-color: $c-dark-blue;
            }
        }

        .files {
            .file {
                background-color: $c-dark-green;

                &:hover {
                    background-color: $c-dark-blue;
                }

                span {                
                    color: $c-white;
                }
            }
        }
    }
}

.uni-input-file {    
    height: 100%;

    .no-files {
        width: 100%;
        height: 100%;
        min-height: 200px;
        background-color: #F8F8F8;
        border: 1px solid #D2D2D2;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 18px;        

        img {
            width: 52px;
        }

        button {
            border: 2px solid #027948;
            border-radius: 20px;
            padding: 6px 24px;
            color: #027948;            
            font-family: 'Open Sans';
            font-weight: 700;
            font-size: 16px;

            &:hover {
                background-color: #CCFFD8;
            }
        }

        .permitted-files {
            font-family: 'Open Sans';
            font-size: 12px;
            color: #595959;
        }
    }

    .files {
        display: flex;
        flex-direction: column;

        label {
            font-size: 14px;
            font-weight: 500;
            color: #666666;
            margin-bottom: 4px;
        }

        .file {
            border: 1px solid #D2D2D2;
            border-radius: 10px;
            background-color: #F8F8F8;
            margin-bottom: 12px;
            display: flex;
            justify-content: space-between;
            padding: 10px 20px;

            &:hover {
                background-color: #CCFFD8;

                span{
                    font-weight: bold;
                }
            }

            span {
                font-weight: 500;
                font-size: 14px;
                color: #464646;
            }
            
            img {
                cursor: pointer;
            }
        }

        button {
            border: 2px dashed #027948;
            border-radius: 4px;
            background-color: initial;
            padding: 6px 24px;
            color: #027948;            
            font-family: 'Open Sans';
            font-weight: 700;
            font-size: 16px;

            &:hover {
                background-color: #CCFFD8;
            }
        }

        .selected-image-wrapper {
            position: fixed;
            overflow: hidden;
            top: 0;
            left: 0;
            background-color: rgb(0 0 0 / 50%);
            display: flex;
            justify-content: center;
            z-index: 100000;
            width: 100%;
            height: 100%;

            img {
                margin: auto;
                max-width: 80vw;
                max-height: 80vh;
            }

            svg {
                cursor: pointer;
            }
        }
    }

    @media screen and (max-width: 910px) {
        .files {
            .selected-image-wrapper {
                top: 72px;
            }
        }
    }
}