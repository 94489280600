@import '../../variables.scss';
.dark .footer, .dark .copyright {
    background-color: $c-dark-green;
    p {
        color: $c-white;
    }
    span {
        color: $c-white;
        a {
            color: $c-white;
        }
    }

    .icons a {
        color: $c-strong-green;
    }

        .p-questions,
        .feedback {

        b {
            color: $c-strong-green;
        }

        a {
            color: $c-strong-green;
        }

        .feedback-button {
            border-color: $c-strong-green;
        }
    }
}

.footer {
    flex-direction: column;
    padding: 20px 85px 0px 150px;
    p {
        color: $c-black;
    }
    span {
        color: $c-span-grey;
        a {
            color: $c-span-grey;
        }
    }
	
	.icons {
		gap: 11px;
	}

    .icons a {
        margin-bottom: 15px;
    }

	.logo {
		display: flex;
		flex-direction: column;
		gap: 30px;
	}
	
    .p-questions {
        font-family: 'Montserrat';
        font-size: 14px;
        margin-top: 10px;

        a {
            color: $c-green;
        }
    }

    .feedback-wrapper {
        background-color: rgb(1, 255, 61, 0.08);
        border-radius: 10px;
        padding: 16px 34px 16px 25px;
    }
	
	.feedback {
        display: flex;
        flex-direction: column;    
        align-items: flex-start;
        gap: 12px;
		font-family: 'Montserrat';		
        font-size: 14px;
		font-weight: 700;
        color: #0F8A38;
		
		.feedback-button {
            font-family: 'Open Sans';
			font-weight: 700;
            padding: 7px 19px;
            font-size: 16px;
            height: 40px;
            border-radius: 20px;
            border: 1px solid #0A3200;
            text-decoration: none;
            background-color: #027948;
            color: #fff;
		}

        b {            
            color: #0F8A38;
        }
	}

    .copyright {
        border-top: 1px solid #F2EFFF;
        padding-top: 11px;
        padding-bottom: 20px;
        margin-top: 20px;
    }
}

@media screen and (max-width: $b-sm) {   
    .footer {
        padding: 10px 10px 0px 30px;
        justify-content: space-between;
		
		.p-questions {
			display: none;
		}
		
		.icons {
			margin-top: 0;
		}

		.icons img {
			width: 20px;
		}

		.icons a {
			margin-bottom: 2px;
		}

		span {
			font-size: 11px !important;
		}
    }    
}

@media screen and (max-width: 740px) {   
    .footer {
        div {
            img {
                width: 60px;
            }
        }   
		
		.icons img {
			width: 18px !important;
		}
    }
}

@media screen and (max-width: 400px) {   
    .footer {
        .feedback-wrapper {
            padding: 16px;
        }

        .feedback .feedback-button {
            padding: 7px 10px;
            font-size: 12px;
            height: 32px;
		}
    }
}