@import '../../variables.scss';

.dark {
    .navigation-wrapper {
        p {
            .program-name-product {
                color: $c-white;
            }
        }
    }
    .card-order-wrapper {
        .card-order {
            border: 1px solid $c-drak-secundary-green;
            color: $c-white;
            box-shadow: none;

            &:hover {
                box-shadow: none;
                border: 1px solid $c-drak-secundary-green;
                transition: .4s;
            }

            .card-order-title {
                color: $c-white;
            }
    
            .card-order-delivery {
                background: $c-dark-blue;

                .table-status {
                    background-color: $c-dark-blue !important;
                }
            }
        }
    }

    .calendar-order-wrapper {       
        ::-webkit-scrollbar-track {
            background-color: $c-dark-blue;
            box-shadow: inset 0 0 5px $c-dark-blue; 
        }
    }

    .calendar-program {
        background-color: $c-dark-green !important;
        border: 1px solid $c-drak-secundary-green !important;

        .react-calendar__tile:disabled {
            background-color: $c-dark-green !important;
            color: $c-white !important;
        }

        .react-calendar__month-view__days__day--neighboringMonth {
            color: #0A4A4A !important;
        }

        .react-calendar__tile--now {
            background-color: inherit !important;
        }
    }
}

.navigation-wrapper {
    margin-bottom: 10px;

    p {
        font-size: 14px;
        font-weight: 600;
        width: fit-content !important;
        margin: 0px;

        .program-name-product {
            font-size: 12px;
            color: #5A5A5A;
        }
    }

    .navigation {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            cursor: pointer;
            color: $c-primary;
        }

        p {
            margin: 0px 40px;
        }
    }
}

.calendar-program {
    width: 265px !important;
    font-size: 14px;
    font-family: 'Montserrat' !important;
    border-radius: 10px;
    border: 1px solid #F2EFFF !important;
    padding: 8px;
    min-height: 235px;
    height: 235px;

    abbr[title] {
        text-decoration: none;
        cursor: auto;
        font-size: 10px !important;
    }

    .react-calendar__tile--active {
        background-color: $c-primary !important;
        border-radius: 10px;
        color: $c-white !important;
    }

    .react-calendar__month-view__days__day--neighboringMonth {
        color: #A5B3BF !important;
    }

    .react-calendar__tile:disabled {
        background-color: $c-white !important;
        color: $c-black !important;

        &.react-calendar__month-view__days__day--neighboringMonth {
            color: #A5B3BF !important;
        }

        &.react-calendar__month-view__days__day--weekend {
            color: #d10000 !important;

            &.react-calendar__month-view__days__day--neighboringMonth {
                color: #A5B3BF !important;
            }
        }
    }

    .react-calendar__tile--now {
        background-color: inherit !important;
    }

    .highlight {
        color: $c-primary;
        border: 1px solid $c-primary !important;
        border-radius: 10px;        
    }

    button {
        height: 30px !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.calendar-order-wrapper {
    ::-webkit-scrollbar {
        width: 8px;
    }
    
    ::-webkit-scrollbar-track {
        background-color: #D9D9D9D9;
        box-shadow: inset 0 0 5px #a3a3a3; 
        border-radius: 10px;
    }
    
    ::-webkit-scrollbar-thumb {
        background: #656565; 
        border-radius: 10px;
    }
    
    ::-webkit-scrollbar-thumb:hover {
        background: #656565; 
    }
}

.card-order-wrapper {
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
    height: 235px;
    margin-left: 10px;
    padding-right: 10px;

    .card-order {
        border: 1px solid #F2EFFF;
        border-radius: 10px;
        padding: 8px;
        width: 200px;
        margin-bottom: 10px;
        height: fit-content;
        cursor: pointer;
        box-shadow: 2px 2px 3px #eeeef0;

        &:hover {
            box-shadow: none;
            border: 1px solid #e8e5f3;
            transition: .4s;
        }

        .card-order-title {
            font-size: 12px;
            color: #5A5A5A;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
                margin: 0px;
                cursor: pointer;
                .item-arrow {
                    transform: rotate(-45deg);
                    width: 8px;
                    margin-left: 3px;
                }
            }

        }

        .card-order-status {
            width: 14px;
            height: 14px;
            border-radius: 50px;
        }

        .card-order-delivery {
            border-radius: 8px;
            background: transparent;
            padding: 10px;
            font-size: 14px;

            .table-status {
                display: flex;
                align-items: center;
                padding: 6px 10px;
                width: 100%;
                font-size: 13px;
                border-radius: 8px;
        
                .table-status-icon {
                    width: 14px;
                    height: 14px;
                    border-radius: 50px;
                    margin-right: 10px;
                }
            }
        }
    }
}

@media screen and (max-width: $b-sm) { 
    .navigation-wrapper {
        p {
            width: fit-content !important;

            span {
                display: none;
            }
            .program-name-product {
                display: none;
            }
        }
    }
}
