@import '../variables.scss';

.input-error {
    .react-date-picker, #qtd-item {
        border: 1px solid $c-error !important;
    }

    select {
        border: 1px solid $c-error !important;
    }
}

.dark {
    .new-order-content {
        background-color: $c-dark-green;

        .divider {
            border-bottom: 1px solid $c-dark-blue;
        }

        .divider-body {
            border-bottom: 1px solid #E4ECF4;
        }

        .new-order-content-header {
            color: $c-strong-green;
            background-color: $c-dark-blue;
        }

        .new-order-content-from {
            background-color: $c-dark-green;


            label {
                color: $c-white !important;
            }

            select {
                color: $c-white;
                background-color: $c-dark-green;
                border: 1px solid $c-label;

                &:focus {
                    border-color: $c-green;
                    box-shadow: 0 0 0 0.25rem $c-green;
                }

                option:hover {
                    background-color: $c-green !important;
                }
            }

            .button-upload {
                border: 2px dashed $c-strong-green;
                &:hover {
                    background-color: $c-dark-blue;
                }
                svg {
                    color: $c-strong-green;
                }

                p {
                    color: $c-strong-green;
                }
            }

            .form-title {
                color: $c-white;;
            }

            .product-info {

                .new-order-snacker {
                    background-color: $c-dark-blue;

                    .new-order-snacker-text {
                        color: $c-white;
                    }

                    img {
                        color: $c-strong-green;
                    }
                }
            }
        }

        .deliveries {
            label {
                color: $c-white !important;
            }

            .form-control {
                background-color: $c-dark-blue;
                border: 1px solid $c-label;
                color: $c-white;

                &::placeholder {
                    color: $c-label;
                }
            }

            .delivery-title {
                color: $c-white;
            }

            .add-deliveries {
                border: 2px dashed $c-strong-green;

                &:hover {
                    background-color: $c-dark-blue;
                }

                svg {
                    color: $c-strong-green;
                }

                p {
                    color: $c-strong-green;
                }
            }

            .accordion {
                .accordion-item {
                    border: none;
                    .accordion-button {
                        background-color: $c-dark-blue;
                        color: $c-white;

                        &::after {
                            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
                        }

                        &:focus {
                            border-color: $c-light-green-secondary;
                        }
                    }

                    .form-select, .form-control{
                        &:focus {
                            border-color: $c-green;
                            box-shadow: 0 0 0 0.25rem $c-green;
                        }
                    }
                }
            }

            .accordion-collapse {
                background-color: $c-dark-blue;
                border: 1px solid #5A5A5A;
                box-shadow: 0px 8px 16px 0px rgba(73, 73, 73, 0.06);
            }

            .wrapper-delivery-form {
                background-color: $c-dark-blue;
                box-shadow: 0px 8px 16px 0px rgba(73, 73, 73, 0.06);
                border: 1px solid #5A5A5A;
                color: #5A5A5A;

                .input-group input {
                    border: 1px solid $c-label;
                    background-color: $c-dark-blue;
                    color: $c-white;
                }

                .date {
                    width: 220px;

                    input[type=number] {
                        background-color: $c-dark-blue;
                        color: $c-white;
                        border: none !important;
                        box-shadow: none !important;
                    }

                    span {
                        color: $c-white;
                    }

                    .react-date-picker {
                        border: 1px solid $c-label;
                        .react-date-picker__calendar {
                            .react-calendar__navigation button:disabled {
                                color: $c-black;
                            }
                            .react-calendar {
                                background-color: $c-dark-blue;
                            }

                            .react-calendar__tile {
                                color: $c-white;

                                &:hover {
                                    background-color: black;
                                }
                            }

                            .react-calendar__tile--active {
                                background-color: $c-primary !important;
                            }

                            .react-calendar__tile--now {
                                background: $c-label;
                            }

                            .react-calendar__navigation__prev-button {
                                color: $c-strong-green;
                            }

                            .react-calendar__navigation__next-button {
                                color: $c-strong-green;
                            }
                        }

                        .react-date-picker__wrapper {
                            button {
                                color: $c-white;
                            }
                        }
                    }
                }

                .counter-delivery {
                    .number {
                        color: $c-white;
                    }
                    .control {
                        background-color: $c-green;
                        color: $c-white;
                    }
                }

                svg {
                    color: $c-white;
                }
            }

            .wrapper-delivery-form-body {
                .body-header {
                    color: $c-content-primary;

                    label {
                    }
                }

                .body-key {
                    color: $c-strong-green;
                }

                .body-remove {
                    color: $c-white;
                }
            }

            .form-select {
                color: $c-white;
                background-color: $c-dark-blue;
                border: 1px solid $c-label;

                &:focus {
                    border-color: $c-green;
                    box-shadow: 0 0 0 0.25rem $c-green;
                }

                option:hover {
                    background-color: $c-green !important;
                }
            }
        }

        .buttons-send-order {
            .btn-outline {
                color: $c-primary;
            }

            button {
                width: 190px;
                font-weight: 600;
                border-radius: 20px;
            }
        }

        .buttons-send-order-resume {
            .btn-outline {
                color: $c-primary;
            }

            button {
                width: 190px;
                font-weight: 600;
                border-radius: 20px;
            }
        }

        .new-order-resume-content {
            .title-resume {
                color: $c-white;
            }

            .new-order-snacker {
                background-color: $c-dark-blue;

                .new-order-snacker-text {
                    color: $c-white;
                }

                img {
                    color: $c-strong-green;
                }
            }

            .accordion {
                .header-acc-resume {
                    border: 1px solid #5A5A5A !important;
                }

                .accordion-item {
                    border: none;
                    background-color: $c-dark-blue;

                    .accordion-button {
                        background-color: $c-dark-blue;
                        color: $c-white;

                        &::after {
                            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
                        }

                        &:focus {
                            border-color: $c-light-green-secondary;
                        }
                    }

                    .form-select, .form-control{
                        &:focus {
                            border-color: $c-green;
                            box-shadow: 0 0 0 0.25rem $c-green;
                        }
                    }

                    p {
                        color: $c-strong-green;
                    }

                    div {
                        align-items: center;

                        svg, img {
                            color: $c-primary;
                        }
                    }
                }

                .accordion-collapse {
                    background-color: $c-dark-blue;
                    border: 1px solid #5A5A5A;
                    box-shadow: 0px 8px 16px 0px rgba(73, 73, 73, 0.06);
                }

                .accordion-body label {
                    color: $c-white;
                }
            }

            .resume-header {
                background-color: $c-dark-green;
            }

            .resume-obs {
                label {
                    color: $c-white;
                }

                textarea.form-control {
                    background-color: $c-dark-green;
                    color: $c-white;
                }
            }
        }
    }

    .new-order-instructions {
        background-color: $c-dark-blue;

        .img {
            height: 220px;
        }

        .instructions {
            padding: 10px 35px;
            .flag {
                margin-top: 20px;
                height: 10px;
                width: 80px;
                margin-bottom: 5px;
                background-color: $c-strong-green;
            }

            .instructions-title {
                font-weight: 600;
            }

            .instructions-body {
                font-size: 13px;
                color: $c-white;
                font-weight: 400;

                svg {
                    height: 14px;
                    color: $c-strong-green;
                }
            }
        }
    }
}

.new-order-content {
    margin-right: 25px;
    margin-top: 15px;
    width: fit-content;
    min-width: 860px;
    max-width: 975px;
    border-radius: 10px;
    background-color: #F5FFF8;
    height: fit-content;
    box-shadow: 0px 8px 16px 0px rgba(73, 73, 73, 0.06);

    .divider {
        border-bottom: 1px solid #E4ECF4;
        width: 100%;
        margin: 30px 0px;
    }

    .divider-body {
        border-bottom: 1px solid #E4ECF4;
        width: 100%;
        margin: 20px 0px;
    }

    .new-order-content-header {
        color: $c-strong-green;
        display: flex;
        background-color: $c-primary;
        height: 54px;
        border-radius: 10px 10px 0px 0px;
        padding: 5px 40px;
        align-items: center;

        img {
            width: 60px;
            margin-top: 3px;
        }

        p {
            font-weight: 600;
            font-size: 18px;
            font-family: 'Montserrat';
            margin: 0px;
            margin-left: 20px;
            width: 70px;
            line-height: 18px;
        }
    }

    .new-order-content-from {
        padding: 20px 40px;
        background-color: $c-white;

        .input-group {
            width: 305px;
        }

        select {
            border-radius: 8px;
            font-size: 14px;
            color: $c-black;
            width: 305px;

            &:focus {
                border-color: $c-light-green-secondary;
                box-shadow: 0 0 0 0.25rem $c-light-green-secondary;
            }

            option:hover {
                background-color: $c-green !important;
            }
        }

        .button-upload {
            width: 305px;
            height: 48px;
            border: 2px dashed $c-primary;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &:hover {
                background-color: $c-light-green-secondary;
            }

            svg {
                color: $c-primary;
                height: 18px;
                margin-right: 10px;
            }

            p {
                margin: 0px;
                color: $c-primary;
                font-weight: 700;
            }
        }

        .form-title {
            color: #5A5A5A;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            font-family: 'Inter' sans-serif;
            margin: 24px 0px;
        }

        .product-info {
            display: flex;
            align-items: center;

            .new-order-snacker {
                width: 555px;
                background-color: $c-green;
                display: flex;
                align-items: center;
                padding: 10px 30px;
                border-radius: 10px;
                margin-left: 40px;

                div {
                    display: flex;
                    align-items: center;
                }

                .new-order-snacker-text {
                    color: $c-white;
                    display: flex;
                    flex-direction: column;
                    align-items: baseline;
                    margin-right: 60px;

                    span {
                        font-size: 10px;
                    }

                    p {
                        font-size: 24px;
                        margin: 0px;
                    }
                }

                img {
                    color: $c-strong-green;
                    height: 45px;
                    margin-right: 18px;
                }
            }
        }

        .new-order-selects {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;

            .p-changes {
                color: $c-warning;
                font-weight: 600;
                font-size: 11px;
            }

            .select-rm {
                .input-group {
                    min-width: fit-content;
                    width: 585px;
                }
            }

            select {
                margin-right: 25px;
            }

            .input-group {
                width: 290px;
            }
        }
    }

    .deliveries {
        margin-top: 15px;
        width: 100%;
        height: fit-content;
        padding: 20px 40px;

        .form-control, .form-select {
            font-size: 14px;
        }

        .deliveries-header {
            margin-bottom: 40px;
        }

        .button-upload {
            width: 305px;
            height: 48px;
            border: 2px dashed $c-primary;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            font-size: 14px;

            &:hover {
                background-color: $c-light-green-secondary;
            }

            svg {
                color: $c-primary;
                height: 18px;
                margin-right: 10px;
            }

            p {
                margin: 0px;
                color: $c-primary;
                font-weight: 700;
            }
        }

        label {
            font-size: 13px;
        }

        .delivery-title {
            font-size: 16px;
            font-weight: 600;
            color: $c-green;
        }

        .add-deliveries {
            align-items: center;
            justify-content: center;
            border: 2px dashed $c-primary;
            border-radius: 10px;
            width: 100%;
            padding: 14px 24px;
            cursor: pointer;

            &:hover {
                background-color: $c-light-green-secondary;
            }

            svg {
                color: $c-primary;
                height: 24px;
                margin-right: 10px;
            }

            p {
                margin: 0px;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                color: $c-primary;
            }
        }

        .accordion {
            margin-bottom: 35px;

            .accordion-item {
                border: none;
                border-radius: 10px;

                .form-select, .form-control {
                    &:focus {
                        border-color: $c-light-green-secondary;
                        box-shadow: 0 0 0 0.25rem #CCFFD8;
                    }
                }

                .accordion-button {
                    background-color: $c-white;
                    width: 30px;
                    box-shadow: none;

                    &:focus {
                        border: none;
                        border-color: $c-light-green-secondary;
                        box-shadow: none;
                    }
                }
            }
        }

        .accordion-collapse {
            background-color: $c-white;
            border: 1px solid #E0E0E0;
            border-top: none;
            box-shadow: 0px 8px 16px 0px rgba(73, 73, 73, 0.06);
        }

        .wrapper-delivery-form {
            width: 100%;
            background-color: $c-white;
            align-items: center;
            justify-content: space-between;
            box-shadow: 0px 8px 16px 0px rgba(73, 73, 73, 0.06);
            border-radius: 10px 10px 0px 0px;
            border: 1px solid #E0E0E0;
            padding: 10px 25px 5px 25px;
            font-size: 14px;
            color: #5A5A5A;
            gap: 10px;

            .date-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-bottom: 16px;
            }

            .date-wrapper-ar {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-bottom: 35px;
            }

            .date {
                width: 220px;

                input {
                    border-radius: 0px;
                    border: none;
                    width: 20px !important;
                    height: 38px;
                    text-align: center;
                    background-color: $c-white;

                    &:last-child {
                        width: 40px !important;
                    }
                }

                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                input[type=number] {
                    -moz-appearance: textfield;
                    &:focus-visible {
                        outline: none;
                    }
                }

                .react-date-picker {
                    border: 1px solid #dee2e6;
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 40px;

                    .react-date-picker__calendar--closed {
                        display: none;
                    }

                    .react-date-picker__calendar {
                        font-family: 'Montserrat';
                        width: 300px;
                        z-index: 1000;

                        abbr {
                            cursor: auto;
                            text-decoration: none;
                        }

                        .react-calendar__navigation button:disabled {
                            color: $c-black;
                            background-color: transparent;
                        }

                        .react-calendar__navigation__next2-button {
                            display: none;
                        }

                        .react-calendar__navigation__prev2-button {
                            display: none;
                        }

                        .react-calendar__tile--active {
                            background-color: $c-primary !important;
                        }

                        .react-calendar__tile {
                            border-radius: 10px;

                            abbr {
                                cursor: pointer;
                            }
                        }

                        .react-calendar__tile--now {
                            background: $c-light-green-secondary;
                        }

                        .react-calendar__navigation button {
                            font-size: 16px;
                            text-transform: capitalize;
                            font-weight: 600;
                            font-family: 'Montserrat';
                        }

                        .react-calendar__navigation__prev-button {
                            font-size: 22px !important;
                            color: $c-primary;
                        }

                        .react-calendar__navigation__next-button {
                            font-size: 22px !important;
                            color: $c-primary;
                        }
                    }

                    .react-date-picker__inputGroup {
                        width: 100%;
                        text-align: center;
                    }
                }

                .react-date-picker__wrapper {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;

                    button {
                        background-color: transparent;
                        border: none;
                    }
                }

            }

            .input-group {
                width: 170px;
                min-width: 0px;
                // width: fit-content;
                max-width: 260px;
                margin-right: 0px;

                input {
                    font-size: 14px !important;
                }
            }

            .input-weight {
                .input-group {
                    min-width: 70px !important;
                    width: fit-content;
                    max-width: 80px !important;
                    margin-right: 20px;
                }
            }

            .counter-delivery {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 30px;
                height: 50px;
                margin-bottom: 12px;

                .number {
                    margin: 0px 14px;
                }

                .control {
                    background-color: $c-primary;
                    color: $c-white;
                    width: 18px;
                    height: 18px;
                    border-radius: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    font-size: 20px;
                    font-weight: 600;

                    svg {
                        height: 14px;
                        margin-right: 0px;
                    }
                }
            }

            svg {
                cursor: pointer;
                margin-right: 10px;
            }
        }

        .wrapper-delivery-form-body {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 60px;
            margin-bottom: 30px;

            #qtd-item {
                height: 30px;
                width: 30px;
            }

            label {
                font-size: 12px;
            }

            .body-header {
                font-size: 14px;
                display: flex;
                flex-direction: column;
                font-weight: 600;
                color: $c-black;
                margin-bottom: 20px;

                input {
                    border-radius: 0px;
                    border: none;
                    width: 20px !important;
                    height: 38px;
                    text-align: center;
                    font-weight: 600;

                    &:last-child {
                        width: 40px !important;
                    }
                }

                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                input[type=number] {
                    -moz-appearance: textfield;
                    height: 28px !important;

                    &:focus-visible {
                        outline: none;
                    }
                }

                .react-date-picker {
                    border: none;
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 28px;

                    .react-date-picker__calendar--closed {
                        display: none;
                    }

                    .react-date-picker__calendar {
                        font-family: 'Montserrat';
                        width: 300px;
                        z-index: 1000;

                        abbr {
                            cursor: auto;
                            text-decoration: none;
                        }

                        .react-calendar__navigation button:disabled {
                            color: $c-black;
                            background-color: transparent;
                        }

                        .react-calendar__navigation__next2-button {
                            display: none;
                        }

                        .react-calendar__navigation__prev2-button {
                            display: none;
                        }

                        .react-calendar__tile--active {
                            background-color: $c-primary !important;
                        }

                        .react-calendar__tile {
                            border-radius: 10px;

                            abbr {
                                cursor: pointer;
                            }
                        }

                        .react-calendar__tile--now {
                            background: $c-light-green-secondary;
                        }

                        .react-calendar__navigation button {
                            font-size: 16px;
                            text-transform: capitalize;
                            font-weight: 600;
                            font-family: 'Montserrat';
                        }

                        .react-calendar__navigation__prev-button {
                            font-size: 22px !important;
                            color: $c-primary;
                        }

                        .react-calendar__navigation__next-button {
                            font-size: 22px !important;
                            color: $c-primary;
                        }
                    }

                    .react-date-picker__inputGroup {
                        width: 100%;
                        text-align: center;
                    }
                }

                .react-date-picker__wrapper {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;

                    button {
                        background-color: transparent;
                        border: none;
                    }
                }
            }

            .body-key {
                font-size: 18px;
                font-weight: 700;
                color: $c-primary;

                img {
                    margin-left: 10px;
                    margin-bottom: 5px;
                }
            }

            .body-remove {
                cursor: pointer;
                font-size: 14px;
                svg {
                    margin-right: 10px;
                }
            }
        }

        .wrapper-delivery-form-inputs {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 25px;
            flex-wrap: wrap;

            select {
                width: 180px;
            }
        }
    }

    .buttons-send-order-resume {
        justify-content: flex-end !important ;
    }

    .buttons-send-order,  .buttons-send-order-resume{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 50px;

        .btn-outline {
            color: $c-primary;
            border: 2px solid;
            margin-right: 30px;
        }

        .spinner-border {color: white;}

        button {
            width: 190px;
            font-weight: 600;
            border-radius: 20px;
        }

        .obs {
            font-weight: 600;
            font-size: 13px;
        }

        .obs-error {
            color: $c-error;
            font-weight: 600;
            font-size: 13px;
        }
    }

    .new-order-resume-content {
        .title-resume {
            font-size: 22px;
            font-weight: 700;
            color: $c-black;
        }

        .new-order-snacker {
            width: 555px;
            background-color: $c-green;
            display: flex;
            align-items: center;
            padding: 10px 30px;
            border-radius: 10px;

            div {
                display: flex;
                align-items: center;
            }

            .new-order-snacker-text {
                color: $c-white;
                display: flex;
                flex-direction: column;
                align-items: baseline;
                margin-right: 60px;

                span {
                    font-size: 10px;
                }

                p {
                    font-size: 24px;
                    margin: 0px;
                }
            }

            img {
                color: $c-strong-green;
                height: 25px;
                margin-right: 18px;
            }
        }

        .accordion {
            margin-top: 30px;
            color: $c-primary;

            .accordion-item {
                border-radius: 10px;
                border: none;
            }

            .accordion-button {
                background-color: $c-white;
                width: 30px;
                box-shadow: none;

                &:focus {
                    border: none;
                    border-color: $c-light-green-secondary;
                    box-shadow: none;
                }
            }

            .accordion-body {
                padding-left: 43px;

                div {
                    margin-bottom: 10px;
                }

                .body-item {
                    min-width: 280px;
                    width: fit-content;
                    max-width: 300px;
                    font-size: 14px;
                }

                label {
                    font-size: 14px;
                    font-weight: 600;
                }

                p {
                    color: $c-black;
                    font-weight: 400;
                }
            }

            p {
                color: $c-primary;
                font-weight: 600;
                margin-right: 10px;
            }

            div {
                align-items: center;

                svg, img {
                    margin-right: 10px;
                    width: 26px;
                    height: 22px;
                    color: $c-primary;
                }
            }

            .accordion-collapse {
                background-color: $c-white;
                border: 1px solid #E0E0E0;
                border-top: none;
                box-shadow: 0px 8px 16px 0px rgba(73, 73, 73, 0.06);
            }

            .header-acc-resume {
                justify-content: space-between;
                align-items: center;
                padding: 0px 40px;
                box-shadow: 0px 8px 16px 0px rgba(73, 73, 73, 0.06);
                border-radius: 10px 10px 0px 0px;
                border: 1px solid #E0E0E0;
            }
        }

        .resume-header {
            background-color: $c-white;
            padding: 20px 40px;
        }

        .resume-body {
            padding: 20px 40px;
        }

        .resume-obs {
            margin-top: 30px;

            label {
                font-size: 14px;
                font-weight: 600;
            }
        }
    }
}

.new-order-instructions {
    width: fit-content;
    min-width: 300px;
    max-width: 302px;
    height: fit-content;
    border-radius: 10px;
    margin-top: 15px;
    box-shadow: 0px 8px 16px 0px rgba(73, 73, 73, 0.06);
    background-color: $c-white;

    .img {
        height: 220px;
        background-image: url('../assets/rules-bg.png');
    }

    .instructions {
        padding: 10px 35px;
        .flag {
            margin-top: 20px;
            height: 10px;
            width: 80px;
            margin-bottom: 5px;
            background-color: $c-strong-green;
        }

        .instructions-title {
            font-weight: 600;
        }

        .instructions-body {
            font-size: 13px;
            color: $c-black;
            font-weight: 400;

            svg {
                height: 14px;
                color: $c-primary;
            }
        }
    }
}

.modal-body-resume {
    span {
        font-weight: 600;
        color: $c-primary;
    }
}

.modal-body-resume-upload {
    flex-direction: column;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;

    .link-upload {
        text-decoration: underline;
        color: $c-primary;
        font-weight: 600;
        cursor: pointer;
    }

    .button-upload {
        width: 305px;
        height: 48px;
        border: 2px dashed $c-primary;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
            background-color: $c-light-green-secondary;
        }

        svg {
            color: $c-primary;
            height: 18px;
            margin-right: 10px;
        }

        p {
            margin: 0px;
            color: $c-primary;
            font-weight: 700;
        }
    }

    .upload-buttons {
        margin-top: 50px;
        .btn {
            min-width: 180px;
            border-radius: 20px;
            font-weight: 600;
        }

        .btn-outline {
            border: 2px solid $c-primary;
            color: $c-primary;
            margin-right: 30px;
        }
    }

    .file-wrapper {
        max-width: 410px;
        width: 410px;
        background-color: $c-light-grey;
        padding: 25px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .file-name {
            margin-right: 10px;

            span {
                font-size: 11px;
            }

            p {
                margin: 0;
            }
        }
        .progress {
            width: 100%;
            height: 5px;
            margin-right: 10px;

            .progress-bar {
                background-color: $c-primary;
            }
        }

        svg {
            cursor: pointer;
        }
    }
}

@media screen and (max-width: 1400px) {
    .new-order-body {
        flex-direction: column-reverse;

        .new-order-instructions {
            display: flex;
            min-width: 200px;
            max-width: 900px;
            width: 100%;

            .instructions-body {
                display: flex;

                p {
                    margin-right: 10px;
                    font-size: 12px;
                }

                svg {
                    height: 12px;
                }
            }

            .img {
                display: none;
            }
        }
    }
}

.error-date {
    color: $c-error;
    font-size: 12px;
    font-weight: 600;
    margin-left: 10px;
}

@media screen and (max-width: $b-md) {
    .new-order-content {
        min-width: 700px;
        width: 700px;

        .new-order-resume-content {
            .accordion .accordion-body {
                .body-item {
                    min-width: fit-content;
                }

                div {
                    flex-wrap: wrap;
                }
            }
        }

        .new-order-content-from {
            .product-info {
                display: flex;
                flex-direction: column-reverse;
                align-items: flex-start;
                gap: 10px;

                .new-order-snacker {
                    margin-left: 0px;
                }
            }
        }

        .deliveries  {
            .accordion .accordion-item .icons-acc {
                justify-content: flex-end;

                svg {
                    height: 16px;
                }
            }

            .wrapper-delivery-form {
                font-size: 12px;
                .inputs-body {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap !important;
                    justify-content: flex-start !important;
                    width: 100%;
                    gap: 20px;
                }

                .counter-delivery {
                    .control svg {
                        height: 16px;
                    }
                }
            }

            .wrapper-delivery-form-body {
                flex-direction: column;

                .mobile-body-top {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                .mobile-body-infos {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 35px;
                    width: 100%;
                }
            }
        }
    }

    .new-order-body {
        flex-direction: column-reverse;
        width: 100%;

        .new-order-instructions {
            display: flex;
            min-width: 200px;
            max-width: 900px;
            width: 90% !important;

            .instructions-body {
                display: flex;

                p {
                    margin-right: 10px;
                    font-size: 12px;
                }

                svg {
                    height: 12px;
                }
            }

            .img {
                display: none;
            }
        }
    }
}

@media screen and (max-width: $b-sm) {
    .new-order-content {
        .new-order-resume-content {
            .accordion .accordion-body {
                .body-item {
                    min-width: fit-content;
                }

                div {
                    flex-wrap: wrap;
                }
            }
        }
    }

    .new-order-body {
        .new-order-instructions {
            display: none !important;
        }
    }
}

@media screen and (max-width: 745px) {
    .new-order-content {
        min-width: 400px;
        width: 100%;

        .new-order-resume-content {
            .title-resume {
                font-size: 18px;
            }

            .new-order-snacker {
                margin-left: 0px;
                width: 98%;
                flex-wrap: wrap;

                .new-order-snacker-text p {
                    font-size: 14px;
                }
            }

            .resume-body {
                padding: 20px 15px;
            }
            .accordion {
                div img {
                    width: 20px;
                    height: 18px;
                }
                div svg {
                    width: 20px;
                    height: 18px;
                }

                .header-acc-resume {
                    font-size: 13px;
                }

                .accordion-body {
                    .body-item {
                        min-width: fit-content;
                        font-size: 12px;

                        label {
                            font-size: 12px;
                        }
                    }

                    div {
                        flex-wrap: wrap;
                    }
                }
            }

        }

        .new-order-content-from {
            .product-info {
                display: flex;
                flex-direction: column-reverse;
                align-items: flex-start;
                gap: 10px;

                .new-order-snacker {
                    margin-left: 0px;
                }
            }
        }

        .deliveries  {
            .accordion .accordion-item .icons-acc {
                justify-content: flex-end;

                svg {
                    height: 16px;
                }
            }

            .wrapper-delivery-form {
                font-size: 12px;
                .inputs-body {
                    flex-wrap: wrap !important;
                    justify-content: flex-start !important;
                    gap: 20px;
                }

                .counter-delivery {
                    .control svg {
                        height: 16px;
                    }
                }
            }

            .wrapper-delivery-form-body {
                flex-direction: column;

                .mobile-body-top {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                .mobile-body-infos {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    gap: 35px;
                    width: 100%;
                }
            }
        }
    }

    .new-order-body {
        flex-direction: column-reverse;
        width: 100%;

        .new-order-instructions {
            display: flex;
            min-width: 200px;
            max-width: 900px;
            width: 90% !important;

            .instructions-body {
                display: flex;

                p {
                    margin-right: 10px;
                    font-size: 12px;
                }

                svg {
                    height: 12px;
                }
            }

            .img {
                display: none;
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .new-order-content {
        min-width: 350px;
        width: 100%;
        margin-right: 0px;
        margin-bottom: 10px;

        .new-order-resume-content {
            .new-order-snacker {
                margin-left: 0px;
                width: 98%;
                flex-wrap: wrap;

                .new-order-snacker-text p {
                    font-size: 14px;
                }
            }

            .resume-body {
                padding: 20px 10px;
            }
            .accordion {
                div img {
                    width: 20px;
                    height: 18px;
                }
                div svg {
                    width: 20px;
                    height: 16px;
                }

                .header-acc-resume {
                    font-size: 12px;
                    padding: 0px 5px;
                }

                .accordion-body {
                    .body-item {
                        min-width: fit-content;
                        font-size: 12px;

                        label {
                            font-size: 12px;
                        }
                    }

                    div {
                        flex-wrap: wrap;
                    }
                }
            }

        }

        .new-order-content-from {
            .product-info {
                display: flex;
                flex-direction: column-reverse;
                align-items: flex-start;
                gap: 10px;

                .new-order-snacker {
                    margin-left: 0px;
                    width: 98%;
                    flex-wrap: wrap;

                    .new-order-snacker-text p {
                        font-size: 14px;
                    }
                }
            }

            .new-order-selects {
                width: 100%;

                .select-rm .input-group {
                    width: 98%;
                }

                .input-group {
                    width: 98%;
                }
            }
        }

        .deliveries  {
            padding: 20px 10px;
            .accordion .accordion-item .icons-acc {
                justify-content: flex-end;
                width: 10% !important;

                svg {
                    height: 16px;
                }
            }

            .wrapper-delivery-form {
                font-size: 12px;

                .input-group {
                    max-width: 100%;
                    width: 100%;
                }

                .input {
                    width: 100%;
                    max-width: 600px;
                }

                .input-weight {
                    width: fit-content !important;
                }

                .inputs-body {
                    flex-wrap: wrap !important;
                    justify-content: flex-start !important;
                    gap: 20px;
                    align-items: center;

                    .input .select {
                        width: 100% !important;
                    }

                    .input-group .form-select {
                        width: 100%;
                        max-width: 100%;
                    }

                    .date-wrapper {
                        width: 100%;
                        max-width: 600px;
                    }

                    .dt-tp {
                        flex-direction: column;

                        width: 100%;

                        .date-wrapper .date {
                            width: 100%;
                            max-width: 500px;
                        }
                    }

                    .qtd-counter {
                        display: flex;
                        justify-content: start;
                        gap: 40px !important;
                        width: 100%;
                    }
                }

                .counter-delivery {
                    .control svg {
                        height: 16px;
                    }
                }
            }

            .wrapper-delivery-form-body {
                flex-direction: column;

                .mobile-body-top {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                .mobile-body-infos {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    gap: 35px;
                    width: 100%;
                }
            }

            .wrapper-delivery-form-inputs {
                div {
                    width: 100%;
                    max-width: 400px;
                }
            }
        }
    }

    .new-order-body {
        flex-direction: column-reverse;
        width: 100%;

        .new-order-instructions {
            display: flex;
            min-width: 200px;
            max-width: 900px;
            width: 90% !important;

            .instructions-body {
                display: flex;

                p {
                    margin-right: 10px;
                    font-size: 12px;
                }

                svg {
                    height: 12px;
                }
            }

            .img {
                display: none;
            }
        }
    }
}

@media screen and (max-width: 630px) {
    .new-order-content .buttons-send-order {
        .obs {
            width: 100%;
            font-size: 11px;
        }

        div {
            flex-direction: column-reverse;
            display: flex;
            gap: 10px;
        }
    }

    .new-order-content .buttons-send-order button, .new-order-content .buttons-send-order-resume button {
        font-size: 12px;
    }
}