@import '../../variables.scss';

.google-visualization-tooltip {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    font-size: 11px !important;
}

.dark {
    .widget-status-ton {
        background-color: $c-dark-green;
        
        .widget-data {
            .widget-title {
                color: $c-white;
    
                .principal-title {
                    color: $c-white;
                }
    
                span {
                    color: $c-white;
                }
            }

            .widget-data-graph {
                .graph-item {
    
                    .widget-divider {
                        border-right: 1px solid #0F3333;
    
                    }
                    
                    &:last-child .widget-divider{
                        border-right: none;
                    }
    
                    span {
                        color: $c-white;
                    }
    
                    p {
                        color: $c-white;
    
                        span {
                            color: $c-white;
                        }
                    }
                }
            }
        }

        .widget-compile {
            background-color: $c-dark-blue;
        }
    }
}

.widget-status-ton {
    height: 175px;
    width: 100%;
    background-color: $c-white;
    margin-top: 30px;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    
    .widget-data {
        padding-top: 16px;
        padding-left: 28px;
        width: calc(100% - 432px);

        .widget-title {
            color: #5A5A5A;
            font-weight: 600;

            .principal-title {
                font-weight: 600;
                color: $c-black;
            }

            span {
                font-weight: 100;
                color: #D0D0D0;
            }
        }

        .widget-line {
            height: 9px;            
            margin-top: 15px;
            max-width: 815px;
            min-width: 610px;
            width: 100%;
            background: linear-gradient(270deg, #00FF3C 12.39%, rgba(217, 217, 217, 0.00) 100%);
            display: flex;
            align-items: center;
            

            .ball-status {
                width: 16px;
                height: 16px;
                border-radius: 50px;
                margin-right: 145px;
                display: flex;
                align-items: center;
                justify-content: center;

                div {
                    width: 12px;
                    height: 12px;
                    border-radius: 50px;
                }
            }
        }

        .widget-data-graph {
            display: flex;

            
            .graph-item {
                margin-top: 12px;
                max-width: 172px;
                min-width: fit-content;
                width: 172px;
                
                .warning-status {
                    background-color: #FF6636;
                    width: 16px;
                    height: 16px;
                    border-radius: 50px;
                    margin-bottom: 15px;
                    margin-left: 10px;
                    text-align: center;
                    font-size: 11px;
                    color: $c-white;
                }

                .item-text {
                    cursor: pointer;
                }

                .item-arrow {
                    transform: rotate(-45deg);
                    width: 8px;
                    margin-left: 5px;
                    cursor: pointer;
                }
                
                .widget-divider {
                    border-right: 1px solid #EEE;
                    height: 75px;
                    margin-left: 18px;
                    margin-right: 18px;

                }
                
                &:last-child .widget-divider{
                    border-right: none;
                }

                span {
                    color: #5A5A5A;
                    font-size: 14px;
                    font-weight: 600;
                }

                p {
                    font-size: 32px;
                    font-weight: 700;
                    margin-bottom: 0px;
                    color: $c-black;

                    span {
                        font-size: 20px;
                        color: $c-black;
                        margin-left: -5px;
                    }
                }
            }
        }
    }

    .widget-compile {
        width: 433px;
        height: 100%;
        background-color: $c-green;
        border-radius: 17px;
        display: flex;
        align-items: center;

        .compile-divider {
            border-right: 1px solid $c-primary;
            height: 129px;
            margin-right: 18px;
        }

        .compile-text {
            color: #ACB9C9;
            width: 100%;
            
            p {
                font-size: 14px;
                margin: 0px;
                font-weight: 600;
            }

            .compile-text-ton {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .up-percent {
                    display: flex;
                    align-items: center;
                    margin-top: 15px;
                    background-color: $c-primary;
                    padding: 4px 8px;
                    border-radius: 5px;
                    p {
                        font-size: 10px;
                        margin-left: 5px;
                    }

                    svg {
                        color: $c-strong-green;
                    }
                }

                .down-percent {
                    display: flex;
                    align-items: center;
                    margin-top: 15px;
                    background-color: #6d0214;
                    padding: 4px 8px;
                    border-radius: 5px;
                    p {
                        font-size: 10px;
                        margin-left: 5px;
                    }

                    svg {
                        color: $c-error;
                    }
                }

                p {
                    color: $c-white;
                    font-size: 34px;
                    font-weight: 700;

                    span {
                        font-size: 24px;
                    }
                }
            }

            .compile-text-percent {
                color: $c-strong-green;
            }
        }

        .back-compile {
            height: 100%;
            display: flex;
            align-items: flex-start;
            flex: 1;
            flex-direction: column;

            img {
                width: 55px;
                align-self: flex-end;
                border-radius: 14px;
            }
        }
    }
}

@media screen and (max-width: $b-sm) { 
    .widget-status-ton {
        height: fit-content;
        background-color: transparent;
        margin-top: 30px;
        border-radius: 20px;
        display: flex;
        justify-content: space-between;
        flex-direction: column-reverse;
        align-items: center;

        .widget-data .widget-data-graph .graph-item {
            .item-text span {
                font-size: 12px;
            }
            
            p {
                font-size: 22px;
            }
        }
    }
}

@media screen and (max-width: 1344px) { 
    .widget-status-ton .widget-data .widget-line .ball-status {
        margin-right: 120px;
    }

    .widget-status-ton .widget-data .widget-title {
        font-size: 14px;
    }
}

@media screen and (max-width: 450px) { 
    .widget-status-ton .widget-compile .compile-text-ton {
        .up-percent, .down-percent {
            display: none !important;
        }
    }
}