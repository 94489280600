@import '../variables.scss';

.dark {
    .util-links-wrapper {
        background: linear-gradient(to bottom, $c-dark-green 30%, transparent 16%, transparent 16%, transparent 16%);
        .util-links-header {
            background-color: $c-dark-blue;

            .util-links-header-i {
                color: $c-strong-green;
            }
        }

        .util-links-body {
            .card-link {
                background: $c-dark-blue;

                .card-country {
                    p {
                        color: $c-white;
                    }
                }

                .links {
                    .link {
                        p {
                            color: $c-green-hover;
                        }

                        a {
                            color: $c-white;
                            svg {
                                color: $c-strong-green;
                            }
                        }
                    }
                }
            }
        }
    }
}

.util-links-wrapper {
    width: 100%;
    max-width: 1310px;
    margin-right: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(to bottom, #165706 125px, transparent 0%, transparent 0%, transparent 0%);
    background-size: 50%;
    border-radius: 10px;

    .util-links-header {
        background-color: $c-primary;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 40px;
        border-radius: 10px 10px 0px 0px;

        .util-links-header-i {
            color: $c-strong-green;
            display: flex;
            align-items: center;

            img {
                width: 55px;
                margin-top: 0px;
            }

            p {
                font-weight: 600;
                font-size: 18px;
                font-family: 'Montserrat';
                margin: 0px;
                margin-left: 40px;
                width: 220px;
                line-height: 18px;
                ;
            }
        }
    }

    .util-links-body {
        display: flex;
       // flex-wrap: wrap;
        margin-top: 40px;
        justify-content: center;
        gap: 50px;

        .card-link {
            border-radius: 10px;
            background: #FFF;
            box-shadow: 0px 8px 16px 0px rgba(73, 73, 73, 0.06);
            min-width: 460px;
            padding: 30px;
            font-weight: 600;

            .card-country {
                display: flex;
                align-items: center;
                gap: 10px;
                p {
                    margin: 0px;
                    color: $c-black;
                }
            }

            .links {
                .link {
                    margin-top: 20px;
                    p {
                        margin: 0px;
                        font-size: 14px;
                        color: $c-green-hover;
                    }

                    a {
                        color: $c-black;
                        text-decoration: none;
                        display: flex;
                        align-items: center;
                        gap: 5px;

                        svg {
                            height: 10px;
                            color: $c-green-hover;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 770px) {
    .util-links-wrapper {
        width: 100%;
        max-width: 1310px;
        margin-right: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: linear-gradient(to bottom, $c-green-hover 30%, transparent 16%, transparent 16%, transparent 16%);
        background-size: 50%;
        border-radius: 10px;

        .util-links-header {
            background-color: $c-primary;
            height: 56px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 5px 40px;
            border-radius: 10px 10px 0px 0px;

            .util-links-header-i {
                color: $c-strong-green;
                display: flex;
                align-items: center;

                img {
                    width: 55px;
                    margin-top: 0px;
                }

                p {
                    font-weight: 600;
                    font-size: 18px;
                    font-family: 'Montserrat';
                    margin: 0px;
                    margin-left: 40px;
                    width: 110px;
                    line-height: 18px;
                }
            }
        }

        .util-links-body {
            display: flex;
            flex-wrap: wrap;
            margin-top: 40px;
            justify-content: center;
            gap: 25px;
            margin-bottom: 10px;

            .card-link {
                border-radius: 10px;
                background: #FFF;
                box-shadow: 0px 8px 16px 0px rgba(73, 73, 73, 0.06);
                min-width: 300px;
                width: 100%;
                padding: 15px;
                font-weight: 600;

                .card-country {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    p {
                        margin: 0px;
                        color: $c-black;
                    }
                }

                .links {
                    .link {
                        margin-top: 20px;
                        p {
                            margin: 0px;
                            font-size: 12px;
                            color: $c-green-hover;
                        }

                        a {
                            color: $c-black;
                            text-decoration: none;
                            display: flex;
                            align-items: center;
                            gap: 3px;
                            font-size: 12px;
                            width: fit-content;

                            svg {
                                height: 10px;
                                color: $c-green-hover;
                            }
                        }
                    }
                }
            }
        }
    }
}