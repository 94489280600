@import '../variables.scss';

.dark {
    .label-all-switch {
        label {
            color: $c-white;
        }
    }
    .my-history-wrapper {
        background-color: $c-dark-green;

        input,
        select {
            background-color: $c-dark-blue;
            color: $c-white;
            border-color: $c-black;
        }

        .my-history-search {
            background-color: $c-dark-green;
        }

        .my-history-header {
            background-color: $c-dark-blue;
        }

        .order-body {
            background-color: $c-dark-blue;
        }

        .table-uni {
            .header-item {
                background-color: $c-dark-green;
                border-color: $c-dark-blue;
                .item-body {
                    label, p{
                        color: $c-white;
                    }
                }
            }
            .accordion {
                box-shadow: 0px 8px 16px 0px rgba(73, 73, 73, 0.06);
            }

            .accordion-item {
                background-color: $c-dark-green;
                color: $c-white;
                border-color: $c-dark-blue;
                padding: 10px;

                .accordion-button {
                    background-color: $c-dark-green;
                    ;
                    color: $c-white;

                    &::after {
                        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
                    }
                }

                // &:nth-child(even) {
                //     background-color: #072d2d;
                //     .accordion-button {
                //         background-color: #072d2d;
                //         color: $c-white;
                //     }
                // }

                .item-header {
                    .item-body-labels-wrapper {
                        label {
                            color: $c-white
                        }
                    }

                    .form-check-input {
                        &:checked {
                            background-color: $c-primary;
                        }

                        &:focus {
                            box-shadow: 0 0 0 0.25rem $c-light-green-secondary;
                        }
                    }
                }
            }

            .accordion-body {
                background-color: $c-dark-blue;
                color: $c-white;
                border-top: 1px solid $c-dark-blue;

                .item-body {
                    .item-body-actions {
                        .order-butons {
                            .button-icon {
                                background-color: $c-dark-green;
                                color: $c-white;
                            }
                        }

                        .btn-outlined {
                            color: $c-strong-green;
                            border: 2px solid $c-strong-green;

                            &:hover {
                                background-color: $c-dark-green;
                            }
                        }
                    }

                    label {
                        color: #dcdc;
                    }

                    p {
                        color: $c-light-grey;
                    }
                }
            }

            .accordion-button {
                background-color: $c-white;

                &:focus {
                    border-color: $c-light-green-secondary;
                }

                border-left: 1px solid #dcdc;
            }

            .table-header {
                background-color: $c-green;
                color: $c-strong-green;

                .form-check-input {
                    &:checked {
                        background-color: $c-green;
                    }

                    &:focus {
                        box-shadow: 0 0 0 0.25rem $c-light-green-secondary;
                    }
                }

                .check-header {
                    background: $c-primary;
                }
            }
        }
    }
}

.my-history-wrapper {
    width: 100%;
    max-width: 1310px;
    margin-right: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $c-white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0px 8px 16px 0px rgba(73, 73, 73, 0.06);

    .my-history-header {
        background-color: #065E39;
        height: 54px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .my-history-search {
        height: 120px;
        background-color: $c-primary;
    }

    .table-uni {
        max-width: 1230px;
        width: 100%;
        margin: 30px 0px;

        .accordion {
            //box-shadow: 0px 8px 16px 0px rgba(73, 73, 73, 0.06);
            padding-bottom: 12px;
            // .accordion-item:first-of-type {
            //     // border-top-left-radius: 0px;
            //     // border-top-right-radius: 0px;
            // }
        }

        .accordion-item {
            box-shadow: 0px 8px 16px 0px rgba(73, 73, 73, 0.06);
            padding-top: 10px;
            font-size: 14px;
            min-height: 75px;

            // &:nth-child(even) {
            //     background-color: #F3F5F2;
            //     .accordion-button {
            //         background-color: #F3F5F2;
            //     }
            // }

            .item-header {
                display: flex;
                align-items: center;
                justify-content: space-between;                

                .table-status-icon {
                    width: 15px;
                    height: 12px;
                    border-radius: 50px;
                    margin-right: 10px;
                }

                div:first-child {
                    margin-left: 18px;
                    min-width: fit-content !important;
                }

                div {
                    min-width: 120px;
                    text-align: center;
                }

                .form-check-input {
                    width: 18px;
                    height: 18px;

                    &:checked {
                        background-color: $c-primary;
                    }

                    &:focus {
                        box-shadow: 0 0 0 0.25rem $c-light-green-secondary;
                    }
                }
            }

            button {
                width: 50px;
            }
        }

        .accordion-body {
            //background-color: #F5FFF8;
            border-top: 1px solid #E0E0E0;
            padding: 30px 60px;

            .item-body {
                .item-body-labels {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-left: 5%;
                    width: 55%;
                    border-bottom: 2px solid #F2F2F2;                                

                    .item-body-labels-wrapper-red {
                        p {
                            color: $c-error;
                        }
                    }
                }

                .item-body-labels-p {
                    color: #027948;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }

                .item-body-actions {
                    width: 20%;
                    margin-left: 80px;

                    .order-butons {
                        display: flex;
                        margin-top: 5px;

                        .button-icon {
                            height: 25px;
                            width: 25px;
                            background-color: $c-primary;
                            color: $c-white;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 8px;
                            margin-right: 10px;
                            margin-bottom: 10px;
                            cursor: pointer;

                            svg {
                                height: 12px;
                            }
                        }
                    }

                    .btn-outlined {
                        color: $c-primary;
                        border: 2px solid $c-primary;
                        width: 130px !important;
                        font-weight: 600;
                        height: 35px;
                        padding: 3px 9px;

                        &:hover {
                            background-color: $c-light-green-secondary;
                        }
                    }
                }

                label {
                    color: #5A5A5A;
                    font-weight: 600;
                    font-size: 12px;
                }

                p {
                    font-size: 14px;
                    color: $c-black;
                    font-weight: 600;
                }
            }
        }

        .header-item {
            background-color: #F5FFF8;
            padding: 30px 60px;
            border-radius: 10px;
            border: 1px solid #E0E0E0;
            box-shadow: 0px 8px 16px 0px rgba(73, 73, 73, 0.06);

            .item-body {

                .item-body-labels {
                    display: flex;
                    justify-content: space-between;

                    .item-body-labels-wrapper-red {
                        p {
                            color: $c-error !important;
                        }
                    }
                }

                .item-body-actions {
                    width: 20%;
                    margin-left: 80px;

                    .order-butons {
                        display: flex;
                        margin-top: 5px;

                        .button-icon {
                            height: 25px;
                            width: 25px;
                            background-color: $c-primary;
                            color: $c-white;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 8px;
                            margin-right: 10px;
                            margin-bottom: 10px;
                            cursor: pointer;

                            svg {
                                height: 20px;
                            }
                        }
                    }

                    .btn-outlined {
                        color: $c-primary;
                        border: 2px solid $c-primary;
                        width: 130px !important;
                        font-weight: 600;
                        height: 35px;
                        padding: 3px 9px;

                        &:hover {
                            background-color: $c-light-green-secondary;
                        }
                    }
                }

                label {
                    color: #5A5A5A;
                    font-weight: 600;
                    font-size: 12px;
                }

                p {
                    font-size: 14px;
                    color: $c-black;
                    font-weight: 600;
                }
            }
        }

        .accordion-button {
            background-color: $c-white;
            width: 30px;
            box-shadow: none;

            &:focus {
                border: none;
                border-color: $c-light-green-secondary;
                box-shadow: none;
                border-left: 1px solid #F2F2F2;
            }

            border-left: 1px solid #F2F2F2;
        }

        .table-header {
            display: flex;
            justify-content: space-between;
            width: 100%;
            background-color: $c-green;
            color: $c-strong-green;
            font-size: 12px;
            height: 40px;
            align-items: center;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;

            .form-check-input {
                width: 18px;
                height: 18px;

                &:checked {
                    background-color: $c-green;
                }

                &:focus {
                    box-shadow: 0 0 0 0.25rem $c-light-green-secondary;
                }
            }

            :nth-child(2) {
                margin-left: -10px;
            }

            .check-header {
                width: 50px;
                background: $c-primary;
                height: 100%;
                border-top-left-radius: 9px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            svg {
                margin-left: 10px;
            }
        }
    }


    .my-history-search {
        display: flex;
        justify-content: space-between;
        padding: 20px 35px 0px 35px;

        label {
            color: $c-white;
            font-weight: 600;
        }

        select {
            min-width: 250px;
        }

        input {
            min-width: 320px;
        }
    }
}

.my-history-header {
    background-color: $c-primary;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 40px;

    .my-history-header-i {
        color: $c-strong-green;
        border-radius: 10px 10px 0px 0px;
        display: flex;
        align-items: center;

        img {
            width: 60px;
            margin-top: 5px;
        }

        p {
            font-weight: 600;
            font-size: 18px;
            font-family: 'Montserrat';
            margin: 0px;
            margin-left: 40px;
            width: 170px;
            line-height: 18px;
        }
    }

    .btn {
        background-color: $c-green;
        display: flex;
        align-items: center;
        font-weight: 600;
        border-radius: 20px;
        padding: 10px 20px;

        img {
            width: 20px;
            margin-right: 10px;
        }
    }
}

.label-all-switch {
    align-items: center;
    display: flex;
    flex-direction: row;

    label {
        margin-right: 25px;
        font-size: 14px;
    }
}

.bottom-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: flex-end;
    width: 35%;
    margin-left: 65%;

    .btn{
        width: 42%;
    }

    .outline-primary{
        width: 100px;
    }
}

@media screen and (max-width: $b-lg) { 
    .my-history-wrapper {
        .table-uni {
            .accordion {
                margin: 10px;
            }

            .accordion-body .item-body .item-body-labels {
                gap: 15px;
                font-size: 12px;
                display: flex;
                justify-content: flex-start;
            }

            .accordion-item {
                .item-header {
                    width: 100%;
                    flex-wrap: wrap;

                    div {
                        min-width: 75px;
                        font-size: 12px;

                        label {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }   
}

@media screen and (max-width: 700px) { 
    .bottom-btn {
        margin-left: 10px;

        .btn {
            width: 200px;
            margin-right: 10px;
        }
    }
    .my-history-wrapper {
        .p-title-body {
            font-size: 14px;
            margin: 0px;
        }
        .table-uni {
            .accordion-body .item-body  {
                .item-body-labels {
                    gap: 15px;
                    font-size: 12px;
                    display: flex;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                }

                label, p {
                    font-size: 12px;
                }
            }

            .header-item .item-body {
                label, p {
                    font-size: 12px;
                }  

                .item-body-labels {
                    flex-direction: column;
                }
            } 

            .accordion-item {
                button {
                    width: 30px;
                }

                .item-header {
                    width: 100%;
                    flex-wrap: wrap;

                    div:first-child {
                        svg {
                            display: none;
                        }
                    }

                    div {
                        min-width: 58px;
                        font-size: 10px;

                        label {
                            font-size: 10px;
                        }
                    }
                }
            }
        }
    }   
}