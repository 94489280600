@import '../variables.scss';

.dark {
    .call-center-wrapper {
        background-color: $c-dark-green;

        input, select {
            background-color: $c-dark-blue;
            color: $c-white;
            border-color: $c-black;
        }

        .call-center-search {
            background-color: $c-dark-green;
        }

        .call-center-header {
            background-color: $c-dark-blue;
        }

        .body-wrapper {
            background-color: $c-dark-blue;
        }

        .table-uni {
            .accordion {
                box-shadow: 0px 8px 16px 0px rgba(73, 73, 73, 0.06);
                height: fit-content;
                overflow: auto;
                max-height: 430px;
            }

            .accordion-item {
                background-color: $c-dark-green;
                color: $c-white;
                border-color: $c-dark-blue;

                &:nth-child(even) {
                    background-color: #072d2d;
                }

                .item-header {
                    .form-check-input {
                        &:checked {
                            background-color: $c-primary;
                        }

                        &:focus {
                            box-shadow: 0 0 0 0.25rem $c-light-green-secondary;
                        }
                    }
                }
            }

            .table-header {
                background-color: $c-green;
                color: $c-strong-green;
                .form-check-input {
                    &:checked {
                        background-color: $c-green;
                    }

                    &:focus {
                        box-shadow: 0 0 0 0.25rem $c-light-green-secondary;
                    }
                }                
            }
        }
    }
}

.call-center-wrapper {
    width: 100%;
    max-width: 1310px;
    margin-right: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $c-white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0px 8px 16px 0px rgba(73, 73, 73, 0.06);

    .call-center-header {
        background-color: #065E39;
        height: 54px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .call-center-search {
        height: 120px;
        background-color: $c-primary;
    }

    .action {
        .button-icon {
            cursor: pointer;
        }
        svg {
            font-size: 16px;
        }
    }

    .table-uni {
        max-width: 1230px;
        width: 100%;
        margin: 20px 0px;

        .accordion {
            box-shadow: 0px 8px 16px 0px rgba(73, 73, 73, 0.06);
            height: fit-content;
            overflow: auto;
            max-height: 430px;

            .accordion-item:first-of-type {
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
            }
        }

        .accordion-item {
            padding-top: 10px;
            padding-bottom: 10px;
            font-size: 14px;
            min-height: 75px;

            &:nth-child(even) {
                background-color: #F3F5F2;                
            }

            .item-header {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .table-status-icon {
                    width: 15px;
                    height: 12px;
                    border-radius: 50px;
                    margin-right: 10px;
                }

                div:first-child {
                    margin-left: 18px;
                    min-width: fit-content !important;

                    &.table-status {
                        margin-left: 0;
                    }
                }

                div {
                    width: 20%;

                    &.action {
                        display: flex;
                        justify-content: end;
                    }
                }


                span {
                    width: 70px;
                    text-align: center;
                }
            }

            button {
                width: 50px;
            }
        }        

        .table-header {
            display: flex;
            justify-content: space-between;
            width: 100%;
            background-color: $c-green;
            color: $c-strong-green;
            font-size: 12px;
            height: 40px;
            align-items: center;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;            

            :nth-child(1) {
                margin-left: 10px;
            }
            
            svg {
                margin-left: 10px;
            }

            .header {
                width: 20%;
            }
        }
    }

    .call-center-search {
        display: flex;
        justify-content: flex-start;
        padding: 20px 35px 10px 35px;
        gap: 30px;
        height: fit-content;

        label {
            color: $c-white;
            font-weight: 600;
        }

        .form-select {
            option {
                &:hover {
                    background-color: $c-green !important;
                }
            }
        }

        select {
            min-width: 250px;

            &:focus {
                border-color: $c-light-green-secondary;
                box-shadow: 0 0 0 0.08rem $c-light-green-secondary;
            }

            option {
                accent-color: $c-primary;
                &:hover {
                    background: $c-green !important;
                    box-shadow: 0 0 10px 100px red inset !important;
                    color:red !important;
                    accent-color: $c-primary
                }

                &:active {
                    accent-color: $c-primary;
                    background-color: $c-green !important;
                    background: $c-green !important;
                }
            }
        }

        input {
            min-width: 200px;
        }

        input[type=date] {
            border: none !important;
        }

        .date-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-bottom: 16px;

            input[type=date] {
                border: none !important;
            }

            input {
                min-width: 50px !important;
            }
        }

        .date {
            width: 220px;

            input {
                border-radius: 0px;
                border: none;
                width: 20px !important;
                height: 38px;
                text-align: center;
                background-color: $c-white;

                &:last-child {
                    width: 40px !important;
                }
            }

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            input[type=number] {
                -moz-appearance: textfield;
                &:focus-visible {
                    outline: none;
                }
            }

            .react-date-picker {
                border: 1px solid #dee2e6;
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 40px;
                background-color: $c-white;

                .react-date-picker__calendar--closed {
                    display: none;
                }

                .react-date-picker__calendar {
                    font-family: 'Montserrat';
                    width: 300px;
                    z-index: 1000;

                    abbr {
                        cursor: auto;
                        text-decoration: none;
                    }

                    .react-calendar__navigation button:disabled {
                        color: $c-black;
                        background-color: transparent;
                    }

                    .react-calendar__navigation__next2-button {
                        display: none;
                    }

                    .react-calendar__navigation__prev2-button {
                        display: none;
                    }

                    .react-calendar__tile--active {
                        background-color: $c-primary !important;
                    }

                    .react-calendar__tile {
                        border-radius: 10px;

                        abbr {
                            cursor: pointer;
                        }
                    }

                    .react-calendar__tile--now {
                        background: $c-light-green-secondary;
                    }

                    .react-calendar__navigation button {
                        font-size: 16px;
                        text-transform: capitalize;
                        font-weight: 600;
                        font-family: 'Montserrat';
                    }

                    .react-calendar__navigation__prev-button {
                        font-size: 22px !important;
                        color: $c-primary;
                    }

                    .react-calendar__navigation__next-button {
                        font-size: 22px !important;
                        color: $c-primary;
                    }
                }

                .react-date-picker__inputGroup {
                    width: 100%;
                    text-align: center;
                }
            }

            .react-date-picker__wrapper {
                display: flex;
                width: 100%;
                justify-content: space-between;

                button {
                    background-color: $c-white;
                    border: none;
                }
            }

        }

        .react-date-picker {
            border: 1px solid #dee2e6;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 40px;
            background-color: $c-white;

            .react-date-picker__calendar--closed {
                display: none;
            }

            .react-date-picker__calendar {
                font-family: 'Montserrat';
                width: 300px;
                z-index: 1000;

                abbr {
                    cursor: auto;
                    text-decoration: none;
                }

                .react-calendar__navigation button:disabled {
                    color: $c-black;
                    background-color: transparent;
                }

                .react-calendar__navigation__next2-button {
                    display: none;
                }

                .react-calendar__navigation__prev2-button {
                    display: none;
                }

                .react-calendar__tile--active {
                    background-color: $c-primary !important;
                }

                .react-calendar__tile {
                    border-radius: 10px;

                    abbr {
                        cursor: pointer;
                    }
                }

                .react-calendar__tile--now {
                    background: $c-light-green-secondary;
                }

                .react-calendar__navigation button {
                    font-size: 16px;
                    text-transform: capitalize;
                    font-weight: 600;
                    font-family: 'Montserrat';
                }

                .react-calendar__navigation__prev-button {
                    font-size: 22px !important;
                    color: $c-primary;
                }

                .react-calendar__navigation__next-button {
                    font-size: 22px !important;
                    color: $c-primary;
                }
            }

            .react-date-picker__inputGroup {
                width: 100%;
                text-align: center;
                display: flex;
                input {
                    min-width: 55px !important;
                    border: none;
                    text-align: center;
                }
            }

            .react-date-picker__inputGroup__divider {
                margin: 5px;
            }
        }

        .react-date-picker__wrapper {
            display: flex;
            width: 100%;
            justify-content: space-between;

            button {
                background-color: transparent;
                border: none;
            }
        }
    }
}

.call-center-header {
    background-color: $c-primary;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 40px;

    .call-center-header-i {
        color: $c-strong-green;
        border-radius: 10px 10px 0px 0px;
        display: flex;
        align-items: center;

        img {
            width: 60px;
            margin-top: 5px;
        }

        p {
            font-weight: 600;
            font-size: 18px;
            font-family: 'Montserrat';
            margin: 0px;
            margin-left: 40px;
            width: 70px;
            line-height: 18px;
        }
    }

    .btn {
        background-color: $c-green;
        display: flex;
        align-items: center;
        font-weight: 600;
        border-radius: 20px;
        padding: 10px 20px;

        img {
            width: 20px;
            margin-right: 10px;
        }
    }
}

@media screen and (max-width: $b-lg) {
    .call-center-wrapper {
        margin-right: 0px;

        .call-center-search {
            flex-wrap: wrap;
            padding: 15px 15px 0px 15px;
            height: fit-content;

            select {
                min-width: 200px;
            }
        }
    }
}

@media screen and (max-width: 1090px) {
    .call-center-wrapper {
        margin-right: 0px;

        .call-center-search {
            flex-wrap: wrap;
            padding: 15px 15px 0px 15px;
            height: fit-content;

            .dates-inputs {
                flex-wrap: wrap;

                label {
                    font-size: 12px;
                }
            }

            select {
                min-width: 200px;
            }
        }

        .table-uni {
            .accordion {
                height: fit-content;
                overflow: hidden;
                max-height: fit-content;
            }

            .accordion-item {
                .item-header {
                    position: relative;
                    div {
                        min-width: 80px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $b-sm) {
    .call-center-wrapper {
        margin-right: 0px;

        .call-center-search {
            flex-wrap: wrap;
            padding: 15px 15px 0px 15px;
            height: fit-content;
            font-size: 12px;

            select {
                min-width: 200px;
                font-size: 12px;
            }

            input, label {
                font-size: 12px;
            }
        }

        .table-uni {
            .accordion {
                height: fit-content;
                overflow: hidden;
                max-height: fit-content;
            }

            .table-header {
                font-size: 10px;                
            }

            .accordion-item {
                .item-header {
                    div {
                        min-width: 50px;
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 770px) {
    .call-center-wrapper {
        margin-right: 0px;

        .call-center-header {
            padding: 5px 20px;

            .call-center-header-i p {
                font-size: 14px;
            }

            a {
                font-size: 13px;
            }
        }

        .call-center-search {
            flex-wrap: wrap;
            padding: 15px 15px 0px 15px;
            height: fit-content;
            font-size: 12px;
            justify-content: baseline;
            flex-direction: column;

            .search-mobile {
                cursor: pointer;
                position: inherit !important;
                top: 0px !important;
                height: inherit !important;
                width: inherit !important;
                text-align: end;
                padding-bottom: 15px;
                font-size: 20px;
            }

            .dates-inputs {
                margin-top: -30px;

                label {
                    font-size: 11px;
                }
            }

            .input-nf-filter {
                margin-bottom: -25px;
            }

            .react-date-picker {
                height: 30px;
            }

            select {
                min-width: 200px;
                font-size: 12px;
            }

            input {
                height: 30px;
            }

            input, label {
                font-size: 12px;
            }

            .search-refresh svg {
                height: 14px;
            }
        }

        .table-uni {
            .accordion {
                height: fit-content;
                overflow: hidden;
                max-height: fit-content;
            }

            .table-header {
                font-size: 10px;                
                .header {
                    display: none;
                }
            }

            .accordion-item {
                .item-header {
                    align-items: flex-start;
                    padding-left: 15px;
                    gap: 8px;

                    div:first-child {
                        margin-left: 0px;
                    }

                    div {
                        text-align: left;
                        width: inherit;
                        min-width: 50px;
                        font-size: 12px;
                        font-weight: 600;

                        span {
                            font-weight: normal;
                        }

                        &.action {
                            display: block;
                            position: absolute;
                            right: 0;
                            top: 36px;
                        }
                    }

                    .table-status {
                        div {
                            font-weight: normal !important;
                        }
                    }                    
                }
            }
        }
    }
}

.table-uni ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    cursor: pointer;
}

.table-uni ::-webkit-scrollbar {
    width: 5px;
}