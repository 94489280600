@import '../variables.scss';

.dark {
    .home-content {
        .tab-wrapper {
            background-color: $c-dark-green;

            .nav .nav-item{
                .active {
                    background-color: $c-strong-green;
                    border-bottom: 2px solid $c-dark-green;
                    color: $c-dark-green;
                }
            }
        }

        .widget-new-order {
            background-color: $c-dark-blue;

            .widget-title, .widget-subtitle {
                color: $c-white;
            }

            .widget-btn {
                .btn-outline-primary {
                    color: $c-strong-green;
                    border: 2px solid $c-strong-green;

                    &:hover {
                        background-color: $c-dark-blue;
                    }
                }

            }
        }

        .widget-my-orders {
            background-color: $c-dark-green;
    
            .widget-my-orders-text{
                .widget-my-orders-title {
                    color: $c-white;
    
                    span {
                        color: $c-white;
                    }
                }
    
                .widget-my-orders-action {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
    
                    p {
                        color: $c-strong-green;
                    }
                }
            }
        }

        .widget-program {
            background-color: $c-dark-green;
        }

        .widget-orders-month {
            background-color: $c-dark-green;
        }

        @media screen and (max-width: $b-sm) {  
            .widget-status-ton {
                .widget-data {
                    background-color: $c-dark-blue;
                }
            }
        }
    }
}

.home-wrapper {
    width: 100%;
    max-width: 1275px;
}

.home-content {
    max-width: 1500px;
    width: 100%;
    
    .wellcome {
        width: 1272px;
        height: 55px;
        background-color: $c-white;
        box-shadow: 0px 8px 16px rgba(10, 50, 0, 0.04);
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;

        p {
            font-size: 22px;
            text-align: center;
            padding-left: 28px;
            padding-top: 14px;
            span {
                font-weight: 700;
            }
        }

        .banner-btn {
            width: 500px;
            height: 55px;
            background-color: $c-primary-hover;
            box-shadow: 0px 8px 16px rgba(10, 50, 0, 0.04);
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 20px;
            border-radius: 10px;

            button {
                background-color: $c-green;
                width: 167px;
                border-radius: 20px;
                font-weight: 700;
                height: 40px;
                padding-bottom: 10px;
                display: flex;
                align-items: center;
                justify-content: center;

                &:active {
                    background-color: $c-green !important;
                }

                img {
                    margin-right: 10px;
                }
            }
        }
    }

    .tab-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: $c-green;
        min-height: 40px;
        height: fit-content;
        max-height: 50px;
        padding-left: 18px;
        border-radius: 20px 20px 0px 0px;
        margin-top: 15px;
        max-width: 100%;
        
        ::-webkit-scrollbar {
            height: 5px;
            margin-left: 10px;
        }
    
        ::-webkit-scrollbar-track {
            background-color: #D9D9D9D9;
            box-shadow: inset 0 0 5px #a3a3a3; 
            border-radius: 5px;
        }
    
        ::-webkit-scrollbar-thumb {
            background: #898989; 
            border-radius: 10px;
        }
    
        ::-webkit-scrollbar-thumb:hover {
            background: #656565; 
        }

        .scroll-tab {
            overflow-x: auto;
        }

        img {
            margin-right: 15px;
        }
        
        .nav {
            margin-bottom: 0px !important;
            border: none;
            font-family: 'Montserrat';
            width: max-content;

            .nav-item {
                min-width: 132px;
                max-width: 215px;
                height: 40px;

                button {
                    width: 100%;
                    border-radius: 0px;
                    color: $c-white;
                    font-weight: 500;
                    font-size: 14px;
                    transition: none !important;

                    &:hover {
                        border: none;
                        border-bottom: 3px solid $c-strong-green;
                    }

                    &:focus {
                        border: none;
                        border-bottom: 3px solid $c-strong-green;
                    }

                    &:focus-visible {
                        border: none;
                        border-bottom: 3px solid $c-strong-green;
                    }
                }

                .active {
                    background-color: $c-green-hover;
                    border: none;
                    border-bottom: 3px solid $c-strong-green;
                }
            }
        }
    }

    .widget-new-order {
        height: 97px;
        width: 731px;
        margin-top: 30px;
        background-color: $c-light-green-secondary;
        border-radius: 10px;
        display: flex;
        font-family: 'Montserrat';
        align-items: center;
        justify-content: flex-end;

        .widget-text {
            margin-right: 22px;
        }

        .widget-title {
            font-size: 18px;
            font-weight: 700;
            color: $c-green;
            margin-bottom: 6px;
        }

        .widget-subtitle {
            font-size: 16px;
            font-weight: 500;
            color: #5A5A5A;
            margin-bottom: 0px;
            font-family: 'Open Sans';
        }

        .widget-btn {
            width: 190px;
            height: 47px;
            margin-right: 40px;
            
            .btn-outline-primary {
                color: $c-green;
                font-weight: 900;
                border-radius: 20px;
                border: 2px solid $c-green;
            }
        }

        .background-icon {
            align-self: flex-end;
            width: 78px;
        }
    }

    .widget-my-orders {
        height: 425px;
        width: 731px;
        margin-top: 30px;
        margin-right: 27px;
        background-color: $c-white;
        padding: 28px 20px;
        border-radius: 20px;

        .widget-my-orders-text{
            display: flex;
            align-items: center;
            justify-content: space-between;

            .widget-my-orders-title {
                font-size: 16px;
                font-weight: 600;
                color: $c-black;

                span {
                    color: #5A5A5A;
                    font-size: 14px;
                }

                .separetor {
                    color: #D0D0D0;
                }
            }

            .widget-my-orders-action {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                svg {
                    margin-right: 10px;
                    width: 10px;
                    color: $c-strong-green;
                    transform: rotate(40deg);
                }

                p {
                    color: $c-primary;
                    margin: 0px;
                    font-size: 14px;
                    font-weight: 700;
                }
            }
        }
    }

    .widget-program {
        width: 514px;
        height: 315px;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        background-color: $c-white;
        padding: 20px;
        border-radius: 15px;
    }

    .widget-orders-month {
        width: 514px;
        height: 207px;
        margin-top: 30px;
        width: 514px;
        background-color: $c-white;
        border-radius: 20px;
    }

    @media screen and (max-width: $b-xl) { 
        .widget-status-ton, .tab-wrapper {
            ::-webkit-scrollbar {
                height: 5px;
                margin-left: 10px;
            }
        
            ::-webkit-scrollbar-track {
                background-color: #D9D9D9D9;
                box-shadow: inset 0 0 5px #a3a3a3; 
                border-radius: 5px;
            }
        
            ::-webkit-scrollbar-thumb {
                background: #898989; 
                border-radius: 10px;
            }
        
            ::-webkit-scrollbar-thumb:hover {
                background: #656565; 
            }
        }

        .widget-wrapper {
            align-items: center;

            .widget-wrapper-program {
                .widget-orders-month {
                    width: 514px;
                    border-radius: 20px;
                }
            }

            .widget-wrapper-orders {
                display: flex;
                flex-direction: column;
                .widget-new-order {
                    width: 680px;

                    .widget-title {
                        font-size: 16px;
                    }

                    .widget-subtitle {
                        font-size: 14px;
                    }
                }

                .widget-my-orders {
                    width: 700px;
                    margin-right: 10px;
                }

            }
        }
    }

    @media screen and (max-width: 1250px) {
        .widget-data {
            overflow-y: hidden;
            overflow-x: overlay;
        }
    }

    @media screen and (max-width: 1344px) {
        .widget-data {
            .widget-data-graph .graph-item {
                background-color: transparent;
                width: 150px !important;
                min-width: 150px !important;

                .widget-divider {
                    margin-left: 8px !important;
                    margin-right: 3px !important;
                }

                .item-text span {
                    font-size: 13px;
                }

                p {
                    font-size: 28px !important;
                }
            }
        }
    }

    @media screen and (max-width: $b-mlg) { 
        .widget-status-ton, .tab-wrapper {

            ::-webkit-scrollbar {
                height: 5px;
                margin-left: 10px;
            }
        
            ::-webkit-scrollbar-track {
                background-color: #D9D9D9D9;
                box-shadow: inset 0 0 5px #a3a3a3; 
                border-radius: 5px;
            }
        
            ::-webkit-scrollbar-thumb {
                background: #898989; 
                border-radius: 10px;
            }
        
            ::-webkit-scrollbar-thumb:hover {
                background: #656565; 
            }
        }

        .widget-wrapper {
            flex-direction: column;
            align-items: center;

            .widget-wrapper-program {
                display: flex;
                justify-content: center;
                width: 100%;

                .widget-orders-month {
                    height: 315px;
                    margin-left: 10px;                    
                }
            }

            .widget-wrapper-orders {
                display: flex;
                align-items: flex-start;

                .widget-my-orders {
                    width: 1030px;
                }

                .widget-new-order {
                    align-self: center;
                }

            }
        }
    }

    @media screen and (max-width: $b-lg) { 

        .widget-status-ton, .tab-wrapper {
            .widget-data {
                overflow-y: hidden;
                overflow-x: overlay;
            }

            ::-webkit-scrollbar {
                height: 5px;
                margin-left: 10px;
            }
        
            ::-webkit-scrollbar-track {
                background-color: #D9D9D9D9;
                box-shadow: inset 0 0 5px #a3a3a3; 
                border-radius: 5px;
            }
        
            ::-webkit-scrollbar-thumb {
                background: #898989; 
                border-radius: 10px;
            }
        
            ::-webkit-scrollbar-thumb:hover {
                background: #656565; 
            }
        }

        .widget-wrapper {
            flex-direction: column;
            align-items: flex-start;

            .widget-wrapper-program {
                display: flex;
                justify-content: flex-start;
                width: 100%;

                .widget-orders-month {
                    height: 315px;
                    margin-left: 10px;
                }
            }

            .widget-wrapper-orders {
                display: flex;
                align-items: flex-start;

                .widget-my-orders {
                    width: 1030px;
                }

                .widget-new-order {
                    align-self: center;
                }

            }
        }
    }

    @media screen and (max-width: 1185px) { 
        .widget-wrapper {
            flex-direction: column;
            align-items: flex-start;
            width: 100%;

            .widget-wrapper-program {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .widget-orders-month {
                    height: 207px;
                }
            }

            .widget-wrapper-orders {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;

                .widget-my-orders {
                    margin-right: 0px;
                    width: 700px;
                }
            }
        }
    }

    @media screen and (max-width: $b-sm) {  
        max-width: 700px;
        margin: 0px auto;
        padding-top: 15px;

        .widget-status-ton {
            .widget-data {
                overflow-y: hidden;
                overflow-x: overlay;
                width: 100%;
                background-color: $c-white;
                border-radius: 20px;
                margin-top: 10px;

                .widget-title {
                    font-size: 13px;
                }
            }

            .widget-compile {
                width: 100%;
                max-width: 430px;
                max-height: 160px;

                .back-compile img {
                    visibility: hidden;
                }

                .compile-text {
                    p {
                        font-size: 12px;
                    }
                    .compile-text-ton {
                        p {
                            font-size: 26px;
                        }
                    }
                }
            }
        }
        
        .tab-wrapper
        {
            margin-top: 0px;
            img {
                display: none;
            }
        }

        .widget-program {
            max-width: 310px;
            width: 100%;
            height: 100%;

            .card-order-wrapper .card-order {
                width: 100%;
                max-width: 230px;
            }

            .navigation-wrapper {
                justify-content: flex-start !important;
                gap: 5px;
                p {
                    font-size: 12px !important;
                }
            }

            .calendar-order-wrapper {
                flex-direction: column !important;
                gap: 20px;
            }
        }

        .widget-wrapper  {
            .widget-wrapper-program {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-bottom: 10px;

                .widget-orders-month {
                    width: 100%;
                    max-width: 450px;
                    height: fit-content;
                }
            }

            .widget-wrapper-orders {
                .widget-new-order{
                    max-width: 500px;
                    width: 100%;

                    .img-truck-btn {
                        width: 100px;
                    }

                    .widget-btn .btn-outline-primary {
                        font-size: 12px;
                        height: fit-content;
                    }
                }

                .widget-my-orders {
                    width: 100% !important;
                    max-width: 550px;
                    margin: 10px 10px;

                    .widget-my-orders-text {
                        .widget-my-orders-title {
                            font-size: 12px;

                            span {
                                font-size: 12px;
                            }
                        }

                        .widget-my-orders-action p {
                            font-size: 11px;
                        }
                    }

                    .table-uni {
                        thead, tbody {
                            font-size: 11px;
                            overflow-x: hidden;
                        }

                        thead {
                            font-size: 9px;
                        }

                        .table-status {
                            font-size: 10px;
                            padding: 0px;

                            .table-status-icon {
                                width: 12px;
                                height: 10px;
                            }
                        }
                    }

                    thead {
                        tr {
                            td {
                                &:last-child {
                                    display: none;
                                }
                            }
                        }
                    }

                    tbody {
                        tr {
                            td {
                                &:last-child {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }        

        .widget-text {
          display: none;
        }
      }
}


@media screen and (max-width: $b-sm) {  
    .home-wrapper {
        width: 100%;
    }
}

@media screen and (max-width: 500px) { 
    .home-content .widget-new-order .background-icon {
        display: none;
    }

    .home-content .widget-wrapper .widget-wrapper-orders .widget-new-order .widget-btn .btn-outline-primary {
        width: fit-content;
    }
}
