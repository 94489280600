//colors :light
$c-primary:#027948;
$c-primary-hover: #027344;
$c-secondary: #00BE82;
$c-warning: #FFC312;
$c-error: #EA2027;
$c-success: #A3CB38;
$c-info: #1289A7;
$c-primary-grey: #666666;
$c-black: #333;
$c-background: #F5F5F5;
$c-light-green: #F2F9F4;
$c-light-green-hover: #e8f9ed;
$c-green: #0A3200;
$c-strong-green: #00FF3C;
$c-light-grey: #F3F5F2;
$c-content-primary: #F3F5F2;
$c-white: #fff;
$c-green-hover: #165706;
$c-drakness-green: #0c1f08;
$c-span-grey:#616F81;
$c-light-green-secondary: #CCFFD8;
$c-drak-secundary-green: #0E3232;
$c-label: #666;
$c-soft-grey: #E4ECF4;

//colors :dark
$c-dark-green: #032424;
$c-dark-blue: #091B1B;
$c-table-alternate-line-color: #072d2d;

//breakpoints
$b-xl: 1400px;
$b-mlg: 1356px;
$b-lg: 1200px;
$b-md: 992px;
$b-sm: 910px;