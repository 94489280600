@import '../variables.scss';
@import url('https://fonts.googleapis.com/css?family=Montserrat');

.card-forgot {
    max-width: 470px;
    width: 470px;
    padding: 24px;

    .pass-requisites {
        p {
            margin: 0px;
        }
    }

    .input-group {
        align-items: center;
    }

    
    .input-pass {
        #senha {
            width: 360px !important;
        }

        #confirm-senha {
            width: 360px !important;
        }

        button {
            border-bottom: 1px solid #dee2e6;
            margin-top: 17px;
            border-radius: 0px;

            svg {
                color: #7b8085;
            }

            &:active, &:focus {
                border-bottom: 1px solid #dee2e6;
                border-top: none;
                border-left: none;
                border-right: none;
            }
        }
    }

    input {
        border-top: none;
        border-right: none;
        border-left: none;
        border-radius: 0px;
        width: 434px !important;
    }

    div p {
        color: #006343;
        margin-bottom: 50px;
    }

    .buttons {
        button {
            height: 50px;
        }
        
        .btn-primary {
            font-weight: 600;
            background-color: $c-green;
            color: $c-strong-green;
            margin-left: 10px;
        }
    
        .action {
            display: flex;
            justify-content: space-between;
            align-items: center;
    
            button {
                width: 220px;
                height: 50px;
            }
    
            .btn-outline-primary {
                color: $c-green;
                border-color: $c-green;
                text-align: center;
                line-height: normal;
                font-weight: 700;
            }
        }
        
    }
}

.timer {
    font-size: 12px;
}

.wrapper-forgot {
    display: flex;
    font-family: 'Montserrat';
}

.section-link {
    p {
        color: $c-black;
        font-size: 14px;
        text-align: center;

        a {
            color: $c-info !important;
            text-decoration: underline !important;
            font-size: 16px;
        }
    }
}

@media screen and (max-width: $b-sm) {
    .card-forgot {
        width: 400px;
        img {
            display: none;
        }

        div p {
            text-align: center;
        }

        input {
            width: 290px !important;
        }

        .buttons {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 35px;
    
            button {
                width: 335px;
            }
            
            .btn-primary {
                font-weight: 600;
                background-color: $c-green;
                color: $c-strong-green;
                height: 50px;
            }
        
            .action {
                display: flex;
                flex-direction: column-reverse;
                justify-content: space-between;
                align-items: center;
        
                button, a {
                    width: 335px;
                    height: 50px;
                }  
    
                p {
                    margin-top: 15px;
                }
        
                .btn-outline-primary {
                    color: $c-green;
                    border-color: $c-green;
                    text-align: center;
                    line-height: normal;
                    font-weight: 700;
                }
            }
            
        }
    }
}

.terms {
    display: flex;
    justify-content: space-around;
    align-items: center;

    span {
        cursor: pointer;
        text-decoration: underline;
    }

    p {
        margin: 0px !important;
    }

    .form-check-input[type=checkbox] {
        width: 20px !important;
        height: 20px;
        cursor: pointer;
        border: 1px solid $c-green;

        &:checked {
            background-color: $c-green;
        }
    }
}