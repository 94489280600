@import '../variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;700&display=swap');

.card-login {
    max-width: 470px;
    width: 470px;
    padding: 24px;

    .input-group {
        align-items: center;
    }

    
    .input-pass {
        #senha {
            width: 360px !important;
        }

        button {
            border-bottom: 1px solid #dee2e6;
            margin-top: 17px;
            border-radius: 0px;

            svg {
                color: #7b8085;
            }

            &:active, &:focus {
                border-bottom: 1px solid #dee2e6;
                border-top: none;
                border-left: none;
                border-right: none;
            }
        }
    }

    input {
        border-top: none;
        border-right: none;
        border-left: none;
        border-radius: 0px;
        width: 434px !important;
    }

    div p {
        color: #006343;
        margin-bottom: 50px;
    }
}

.wrapper-lng {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;

    .lng {
        margin-right: 10px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;

        img {
            margin-right: 2px;
        }
    }
}

.wrapper-login {
    display: flex;
    font-family: 'Montserrat';
}

.section-link {
    p {
        color: $c-black;
        font-size: 14px;
        text-align: center;

        a {
            color: $c-info !important;
            text-decoration: underline !important;
            font-size: 16px;
        }
    }
}

.banner {
    width: 850px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-image: url('../assets/bgLogin.png');
    background-size: cover;
    background-position: center;

    div {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 50px;
        color: $c-white;

        .title-login {
            font-weight: 600;
            font-size: 30px;
            width: 280px;
            line-height: 34px;
            max-width: 530px;
        }

        .subtitle-login {
            font-weight: 500;
            font-size: 16px;
            width: 280px;
            line-height: 22px;
        }

        img {
            display: none;
        }
    }

    // img {
    //     margin: 40px;
    //     width: 105px;
    // }

    // p {
    //     max-width: 350px;
    //     margin-bottom: 60px;
    //     margin-left: 40px;
    // }
}

.wrapper-card {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttons {
    button {
        width: 430px;
    }
    
    .btn-primary {
        font-weight: 600;
        background-color: $c-green;
        color: $c-strong-green;
        height: 50px;
    }

    .action {
        display: flex;
        justify-content: space-between;
        align-items: center;

        button, a {
            width: 220px;
            height: 50px;
        }

        a {
            padding-top: 14px;
        }


        .btn-outline-primary {
            color: $c-green;
            border-color: $c-green;
            text-align: center;
            line-height: normal;
            font-weight: 700;
        }
    }
    
}

@media screen and (max-width: $b-md) {
    .banner {
        div {
            .title-login {
                font-size: 45px;
            }
    
            .subtitle-login {
                font-size: 16px;
            }
        }
    }
}

@media screen and (max-width: $b-sm) {
    body {
        overflow-x: hidden;
    }
    
    .wrapper-login {
        display: flex;
        flex-direction: column;
    }
    
    .wrapper-card {
        width: 100%;
        flex-direction: column;
    }

    .wrapper-lng {
        position: relative;
        display: flex;
        top: auto;
        right: auto;
        margin-top: 20px;
        margin-bottom: 10px;
    
        .lng {
            cursor: pointer;
            font-size: 12px;
            font-weight: 600;
    
            img {
                margin-right: 2px;
                width: 18px;
            }
        }
    }

    .banner {
        width: 100vw;
        height: 270px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background-image: url('../assets/bgLogin.png');
        background-position: left;

        div {
            margin-left: 20px;
            margin-top: 30px;
            align-items: flex-start;
            justify-content: flex-start;
            .title-login {
                font-size: 22px;
                padding-bottom: 25px;
                max-width: fit-content;
            }
    
            .subtitle-login {
                display: none;
            }

            img {
                display: none;
                position: absolute;
                top: 25px
            }
        }
    }

    .card-login {
        width: 400px;
        img {
            display: none;
        }

        .input-pass {
            #senha{
                width: 290px !important;
            }
        }
    }

    .buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 35px;

        button {
            width: 335px;
        }
        
        .btn-primary {
            font-weight: 600;
            background-color: $c-green;
            color: $c-strong-green;
            height: 50px;
        }
    
        .action {
            display: flex;
            flex-direction: column-reverse;
            justify-content: space-between;
            align-items: center;
    
            button, a {
                width: 335px;
                height: 50px;
            }  

            p {
                margin-top: 15px;
            }
    
            .btn-outline-primary {
                color: $c-green;
                border-color: $c-green;
                text-align: center;
                line-height: normal;
                font-weight: 700;
            }
        }
        
    }
}