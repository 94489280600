@import '../../variables.scss';

.dark {
    .date-picker-range-wrapper{
    background-color: $c-dark-green;

        .date-picker-options-wrapper {
            .date-picker-option{
                background: $c-dark-green;
                &:hover {
                    background: $c-green;
                    color: $c-strong-green;
                }
            }

            .date-picker-option-active {
                background: $c-primary;
                    color: $c-white;
            }
        }
    }
}

.date-picker-range-wrapper {
    position: absolute;
    background-color: white;
    padding: 13px;
    border-radius: 6px;
    box-shadow: 0px 4px 8px 0px #00000026;
    max-width: 275px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .date-picker-options-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 6px;
        font-size: 13px;
        font-weight: 600;
        margin-bottom: 20px;

        .date-picker-option {
            padding: 5px 10px;
            background: #f5f5f5;
            border-radius: 5px;
            cursor: pointer;

            &:hover {
                background: $c-primary;
                color: $c-white;
            }
        }

        .date-picker-option-active {
            padding: 5px 10px;
            background: $c-primary;
            color: $c-white;
            border-radius: 5px;
            cursor: pointer;
        }
    }
}