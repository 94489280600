@import '../variables.scss';

.dark {
    .mail-setting-wrapper {
        background: linear-gradient(to bottom, $c-dark-green 125px, transparent 16%, transparent 16%, transparent 16%);
        .mail-setting-header  {
            background-color: $c-dark-blue;

            .mail-setting-header-i {
                color: $c-strong-green;
            }
        }
    }

    .mail-setting-body{
        background-color: #091B1B;
    }
    .mail-setting-body{
        .head-all{
            span{
                color: $c-secondary;
            }
        }
    }

    .option-area{
        color:$c-secondary;
    }
}

.mail-setting-wrapper{
    width: 100%;
    max-width: 1310px;
    margin-right: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(180deg, #165706 125px, transparent 0, transparent 0, transparent 0);
    background-size: 50%;
    border-radius: 10px;
}

.fa-xl {
    font-size: 2.5em;
}

.buttons-save {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;

    .btn-outline {
        color: $c-primary;
    }

    button {
        width: 190px;
        font-weight: 600;
        border-radius: 20px;
    }
}

.mail-setting-header {
    background-color: $c-primary;
    height: 56px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 5px 40px;
    border-radius: 10px 10px 0px 0px;

    .mail-setting-header-i {
        color: $c-strong-green;
        border-radius: 10px 10px 0px 0px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        img {
            width: 60px;
            margin-top: 5px;
        }

        p {
            font-weight: 600;
            font-size: 18px;
            font-family: 'Montserrat';
            margin: 0px;
            margin-left: 40px;
            line-height: 18px;
        }
    }

    .btn {
        background-color: $c-green;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        font-weight: 600;
        border-radius: 20px;
        padding: 10px 20px;

        img {
            width: 20px;
            margin-right: 10px;
        }
    }
}

.mail-setting-body{

    min-width:1230px;
    background-color: $c-white;
    box-shadow: 0px 8px 16px 0px rgba(73, 73, 73, 0.06);
    padding:25px;
    margin-top:20px;
    border-radius: 10px;

    .body-area{
        width: 100%;
        height: fit-content;
        gap: 0px;
        border-radius: 10px 0px 0px 0px;
        border: 1px 0px 0px 0px;
    }

    .table-options{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
    }

    .column {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        gap: 20px;
        padding-bottom: 24px;
    }

    .body-head{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 12px;
        align-items: center;
    }

    .body-title{
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 600;
        line-height: 24.38px;
        text-align: left;
    }

    .head-all{
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        span{
            padding-right: 15px;
            height: 20px;
            font-size: 15px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0.4000000059604645px;
            text-align: right;
            color: #333;
            font-family: "Open Sans";
            font-size: 16px;
      }
    }
}

.option-area{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-right: 10px;
    align-items: center;
}

.divider-column {
    border-left: 0.8px solid $c-soft-grey;
    padding-left: 15px;
    width: 90%;
}

.divider-line {
    border-bottom: 0.8px solid $c-soft-grey;
    margin: 0px 0px 30px 0px;
    width: 100%;
}

@media screen and (max-width: $b-lg) {
    .mail-setting-wrapper{
        margin-right: 0px;
    }
    .mail-setting-body {
        min-width: fit-content;

         .body-area {
            min-width: fit-content;
         }
    }
}

@media screen and (max-width: 1090px) {
    .mail-setting-wrapper{
        margin-right: 0px;
    }
    .mail-setting-body {
        min-width: fit-content;

         .body-area {
            min-width: fit-content;
         }
    }
}

@media screen and (max-width: $b-sm) {

    .mail-setting-body {
        min-width: fit-content;

         .body-area {
            min-width: fit-content;
         }
    }
}

@media screen and (max-width: 770px) {
    .mail-setting-wrapper{
        margin-right: 0px;
        .mail-setting-header {
            padding: 5px 20px;

            .mail-setting-header-i p {
                font-size: 14px;
            }

            a {
                font-size: 13px;
            }
        }
    }

    .mail-setting-body {
        min-width: fit-content;

         .body-area {
            min-width: fit-content;
         }
    }

}

@media screen and (max-width: $b-mlg) {
    .mail-setting-wrapper{
        margin-right: 0px;
        .mail-setting-header {
            padding: 5px 20px;

            .mail-setting-header-i p {
                font-size: 16px;
            }

            a {
                font-size: 13px;
            }
        }

        .body-head{
            flex-direction: column;
        }
    }

    .mail-setting-body {
        min-width: fit-content;

         .body-area {
            min-width: fit-content;
         }
    }
}

@media screen and (max-width: 1430px) {

    .mail-setting-wrapper {
        width: 95%;
    }
    .mail-setting-body {
        width: 95%;
        min-width: 95%;
    }
}