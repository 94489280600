@import '../../variables.scss';

.dark{

    .wrapper-card{
        .wrapper-accordions{
            .header{
                color:$c-strong-green;
            }

            .detail-title {
                color: $c-secondary;
            }
            .accordion-button:not(.collapsed) {
                background-color: $c-dark-blue;
            }

            .accordion-button {
                background-color: $c-dark-blue;
            }

            .accordion-item {
                background-color: $c-dark-blue;
                color:$c-white;
            }

            .links {
                .link {
                    a {
                        color: $c-secondary;
                        svg {

                            color: $c-light-green-secondary;
                        }
                    }
                }
            }
        }
    }
}

.wrapper-card{
    min-width: 460px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 50%;

    .accordion-button:not(.collapsed) {
        background-color:$c-white;
    }

    .accordion-item:first-of-type {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .flag-area{
        max-height: 53px;
        border-radius: 10px 10px 0 0;
        width: 100%;
        height: 500px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        padding-left: 24px;

        .flag-header{
            font-weight: 600;
            font-size: 20px;
            align-items: center;
            color:$c-white;

        }
    }

    .wrapper-accordions{
        width: 100%;

        .icons{
            padding-right: 12px;
            color:$c-strong-green;
            font-size: 24px;
        }

        .header{
            color:$c-black;
            font-size: 20px;
            line-height: 24.38px;
            font-weight: 700;
        }

        .detail-title{
            color: $c-green-hover;
            font-size: 14px;
            font-weight: 600;
        }

        .detail{
            font-size:16px;
            font-weight: 600;
        }

        .links {
            .link {
                margin-top: 20px;
                    margin: 0px;
                    font-size: 16px;

                a {
                    color: $c-green-hover;
                    text-decoration: none;
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    font-size: 16px;
                    font-weight: 600;

                    svg {
                        height: 10px;
                        color: $c-green-hover;
                    }
                }
            }
        }

    }
}


