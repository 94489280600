@import '../../variables.scss';
.dark header {
    background-color: $c-dark-green;

    .breadcrumb {
        .breadcrumb-item {
            a {
                color: $c-white;
            }
        }
    }

    .theme-buttons {
        svg {
            color: black;
        }

        .active {
            color: $c-strong-green;
        }
    }

    .icons {
        .icon-notification {
            cursor: pointer;
            height: 20px;
            color: $c-strong-green;
            background-color: #091B1B;
            padding: 10px;
            border-radius: 100px;
        }
        ::-webkit-scrollbar-track {
            background-color: $c-dark-blue;
            box-shadow: inset 0 0 5px $c-dark-blue;
        }

        .notification-body {
            background: $c-dark-green;
            box-shadow: -1px 8px 20px #333333;

            .notification-header {
                border-bottom: 1px solid #E4ECF4;

                svg {
                    color: #FF6636;
                }
            }

            .notification-text-wrapper {
                border-bottom: 1px solid #E4ECF4;

                &:hover {
                    background-color: $c-dark-blue;
                }

                .notification-icon {
                    background-color: $c-primary;

                    svg {
                        color: $c-white;
                    }
                }
            }
        }
    }

    .dropdown-m {
        .selected-customer{
            color:  $c-white;
        }
    }
}

header {
    display: flex;
    background-color: $c-white;
    align-items: center;
    padding: 16px 40px 16px 100px;
    justify-content: space-between;
    height: 72px;

    div {
        display: flex;
        align-items: center;
    }

    .divider {
        height: 72px;
        border-right: 1px solid #E4ECF4;
    }

    .breadcrumb {
        margin-bottom: 0px;
        .breadcrumb-item {
            margin-left: 70px;
            margin-bottom: 0px;
            a {
                text-decoration: none;
                color: #84878F;
            }
        }
    }

    .links {
        a {
            text-align: center;
            font-size: 14px;
            font-weight: 500;
            color: $c-strong-green !important;
            text-decoration: none !important;
            cursor: pointer;
            min-width: 120px;
            padding-bottom: 0px;
            &:hover {
                padding-bottom: 5px;
                border-bottom: 2px solid $c-strong-green !important;
            }
        }
    }

    .icons {
        align-self: flex-end;

        span {
            color: #444444;
        }

        .notification .badge {
            top: 23px;
            position: absolute;
            height: 15px;
            width: 15px;
            border-radius: 100px;
            font-size: 9px;
            background: #FF6636;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 18px;
            cursor: pointer;

            &::selection {
              background: transparent;
            }
        }

        ::-webkit-scrollbar {
            width: 8px;
        }

        ::-webkit-scrollbar-track {
            background-color: #D9D9D9D9;
            box-shadow: inset 0 0 5px #a3a3a3;
            border-radius: 10px;
        }

        ::-webkit-scrollbar-thumb {
            background: #656565;
            border-radius: 10px;
        }

        ::-webkit-scrollbar-thumb:hover {
            background: #656565;
        }

        .notification-body {
            height: 150px;
            width: 320px;
            background: white;
            position: absolute;
            top: 60px;
            border-radius: 10px;
            margin-left: -120px;
            box-shadow: -1px 8px 20px #5c5c5c;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            overflow-y: auto;
            transition: 'opacity .5s ease-out';
            z-index: 100;

            .notification-header {
                font-size: 12px;
                font-family: 'Montserrat';
                font-weight: 600;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                padding: 5px 10px;
                border-bottom: 1px solid #E4ECF4;

                svg {
                    color: #FF6636;
                    cursor: pointer;
                    height: 16px;
                }

                p {
                    margin: 0px;
                }
            }

            .notification-text-wrapper {
                display: flex;
                padding: 10px;
                border-bottom: 1px solid #E4ECF4;
                cursor: pointer;

                &:hover {
                    background-color: #F3F5F2;
                }

                .notification-icon {
                    background-color: #027948;
                    border-radius: 15px;
                    height: 28px;
                    width: 28px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 9px;

                    svg {
                        color: $c-white;
                        height: 14px;
                    }
                }

                .notification-text {
                    font-size: 12px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    font-weight: bold;
                    font-family: 'Montserrat';
                    width: 255px;

                    .notification-title {
                        margin-bottom: 3px;
                        font-weight: bold;
                    }

                    .notification-date {
                        font-size: 10px;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        gap: 3px;
                        justify-content: flex-end;
                        margin-top: 10px;
                        p {
                            margin: 0px;
                        }
                    }

                    p {
                        margin: 0px;
                        text-align: center;

                        &::selection {
                            background: transparent;
                        }
                    }
                }
            }
        }

        .icon-notification {
            cursor: pointer;
            height: 20px;
            color: $c-primary;
            background-color: #D1FADF;
            padding: 10px;
            border-radius: 100px;
            animation: ring 4s .7s ease-in-out alternate;
        }
    }

    .dropdown-m {
        .f-check {
            padding: 4px 7px 4px 16px;

            .lng-check {
                color: $c-primary;
                text-decoration: underline;
            }

            .form-check {
                .form-check-input {
                    min-width: 30px;
                }

                .form-check-label {
                    font-weight: 400;
                    color: #212529;
                    font-size: 16px;
                }

                .form-check-input:checked {
                    background-color: $c-primary;
                    border-color: $c-primary;
                }

                .form-check-input:focus {
                    border-color: $c-primary;
                    outline: 0;
                    box-shadow: 0 0 0 0.25rem #f0fff3;
                }
            }

            .lng {
                cursor: pointer;
                padding-right: 7px;

                span {
                    margin-left: 2px;
                    font-weight: 600;
                }

            }
        }
        .selected-customer{
            font-size: 8px;
        }
        .dropdown-menu.show {
            min-width: 170px
        }
    }

    .theme-buttons {
        margin-left: 60px;

        svg {
            cursor: pointer;
            padding: 7px;
            background-color: $c-light-grey;
        }

        .active {
            color: $c-strong-green;
            background-color: $c-drak-secundary-green;
        }

        .sun {
            border-radius: 8px 0px 0px 8px;
        }

        .moon {
            border-radius: 0px 8px 8px 0px;
        }
    }

    .customers {
        font-size: 13px;
        font-weight: 600;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: baseline;
        margin-bottom: 10px;
        padding: 5px;
        cursor: pointer;

        &:hover {
            background-color: $c-light-grey;
        }

        p {
            margin: 0px;
        }

        svg {
            margin-right: 10px;
        }
    }

    .customers-active {
        background-color: $c-light-grey;

        svg {
            color: $c-primary;
        }
    }
}

@keyframes ring {
    0% { transform: rotate(0); }
    1% { transform: rotate(30deg); }
    3% { transform: rotate(-28deg); }
    5% { transform: rotate(34deg); }
    7% { transform: rotate(-32deg); }
    9% { transform: rotate(30deg); }
    11% { transform: rotate(-28deg); }
    13% { transform: rotate(26deg); }
    15% { transform: rotate(-24deg); }
    17% { transform: rotate(22deg); }
    19% { transform: rotate(-20deg); }
    21% { transform: rotate(18deg); }
    23% { transform: rotate(-16deg); }
    25% { transform: rotate(14deg); }
    27% { transform: rotate(-12deg); }
    29% { transform: rotate(10deg); }
    31% { transform: rotate(-8deg); }
    33% { transform: rotate(6deg); }
    35% { transform: rotate(-4deg); }
    37% { transform: rotate(2deg); }
    39% { transform: rotate(-1deg); }
    41% { transform: rotate(1deg); }

    43% { transform: rotate(0); }
    100% { transform: rotate(0); }
  }

  @media screen and (max-width: $b-sm) {
    .group-menu {
        position: fixed;
        height: fit-content !important;
        width: 100%;
        z-index: 1000000;
    }

    header {
        padding: 16px 30px 16px 80px;
        gap: 40px;
        width: 100%;
        max-width: 100%;
        .breadcrumb, .divider, nav {
            display: none;
        }

        div {
            img {
                width: 60px;
            }
        }

        .theme-buttons {
            margin-left: 20px;

            svg {
                height: 12px;
            }
        }

        .notification {
            margin: 0px;
            margin-right: -15px;
        }
        .icons .icon-notification {
            height: 12px;
            padding: 7px;
        }
    }

    .dark {
        header {
            padding: 16px 30px 16px 80px;
            gap: 40px;
            width: 100%;
            max-width: 100%;
            .breadcrumb, .divider, nav {
                display: none;
            }

            div {
                img {
                    width: 60px;
                }
            }

            .theme-buttons {
                margin-left: 20px;

                svg {
                    height: 12px;
                }
            }

            .notification {
                margin: 0px;
                margin-right: -15px;
            }
            .icons .icon-notification {
                height: 12px;
                padding: 7px;
            }
        }
    }
  }