$theme-colors: (
  "danger": #EA2027,
  "primary": #027948,
  );

@import 'bootstrap/scss/bootstrap';
@import './variables.scss';


@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans'), local('OpenSans'), url(https://fonts.gstatic.com/s/opensans/v13/cJZKeOuBrn4kERxqtaUH3T8E0i7KZn-EPnyo3HZu7kw.woff) format('woff');
}

.dark {
    color: white;

    .table-uni {
        thead {
            color: $c-strong-green;
        }

        tr {
            border-bottom: 1px solid $c-drak-secundary-green;
        }
    }

    .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
        background-color: $c-primary !important;
    }

    .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
        color: $c-light-grey !important;
    }

    .input-group-text {
        color: $c-white;
        background-color: $c-dark-blue;
        border: var(--bs-border-width) solid $c-black;
    }

    .react-datepicker__header{
        background-color: $c-dark-blue;
    }

    .react-datepicker__current-month{
        color: $c-white;
    }

    .react-datepicker__day-name{
        color: $c-white;
    }
    .react-datepicker{
        background-color:$c-dark-blue;
    }

    .react-datepicker__day{
        color: $c-white;
    }

    .react-datepicker__day:hover,
    .react-datepicker__month-text:hover,
    .react-datepicker__quarter-text:hover,
    .react-datepicker__year-text:hover {
      background-color: $c-light-green-secondary;
      color:$c-black;
    }
}

html,
body {
  margin: 0px;
  height: 100%;
}

body {
    color: $c-black;
    font-family: 'Open Sans';
}

.headline {
    &-lg {
        font-size: 32px;
        color: $c-black;
        font-weight: 700;
    }

    &-sm {
        font-size: 24px;
        color: $c-primary-grey;
        font-weight: 700;
    }
}

.title {
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    color: #fff;
}

.p-action {
    cursor: pointer;
    color: #0F8A38;
    margin-bottom: 0px !important;

    &:hover {
        text-decoration: underline;
    }
}

.p-error {
    color: $c-error;
    display: flex;
    text-align: center;
    font-weight: 600;
    align-items: center;
    justify-content: center;
}

.btn {
    border-radius: 12px;
    padding: 12px 16px;
    max-width: 434px;
    height: 44px;
    font-size: 14px;

    &-primary {
        background-color: $c-primary;
        border: none;

        &:hover{
            background-color: $c-primary-hover;
        }

        &:active{
            background-color: $c-primary-hover !important;
        }
    }

    &-secondary {
        background-color: $c-light-green;
        color: $c-primary;
        border: none;

        &:hover{
            background-color: $c-light-green-hover;
            color: $c-primary;
        }

        &:active {
            background-color: $c-light-green-hover !important;
            color: $c-primary !important;
        }
    }

    &-outline-primary {
        color: $c-primary;
        border-color: $c-primary;
        font-weight: 600;
        border-width: 2px;

        &:hover{
            background-color: $c-light-green-hover;
            border-color: $c-primary;
            color: $c-primary;
        }

        &:active {
            background-color: $c-light-green-hover !important;
            border-color: $c-primary !important;
            color: $c-primary !important;
        }
    }
}

.dropdown-menu .dropdown-item:active{
    background-color: $c-primary;
}

.input-error {
    select, input, textarea {
        border: 1px solid $c-error !important;
    }
}

.error-mandatory {
    font-weight: 700;
    color: $c-error;
    font-size: 14px;
}

.button-close, .button-confirm {
    font-family: 'Open Sans';
    font-weight: 700;
    border: 2px solid #027948;
    border-radius: 20px;
    padding: 12px;
    width: 190px;
}

.button-close {
    background-color: #fff;
    color: #027948;
    margin-right: 30px;

    &:hover {
        background-color: #CCFFD8;
    }
}

.button-confirm {
    background-color: #027948;
    color: #fff;

    &:hover {
        background-color: #027344;
    }

    .spinner-border {
        color: #fff;
    }
}

.header-back-button {
    background-color: transparent;
    color: #fff;
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 700;
    border: 2px solid #fff;
    border-radius: 20px;
    padding: 8px;
    width: 167px;

    &:hover {
        background-color: #027344;
    }
}

.dark {
    .button-confirm, .button-close {
        background-color: $c-dark-green;
        color: #027948;
    
        &:hover {
            background-color: $c-dark-blue;
        }
    }

    .header-back-button {
        background-color: $c-dark-green;
        border-color: #027948;
        color: #027948;
    
        &:hover {
            background-color: $c-dark-blue;
        }
    }
}

.no-margin {
    margin: 0 !important;
}

.no-padding {
    padding: 0 !important;
}

.modal-dialog {
    max-width: 600px;

    .modal-content {
        padding-bottom: 70px;
        text-align: center;

        .modal-header {
            flex-direction: column-reverse;
            border: none;

            svg {
                margin-top: 30px;
                height: 70px;
            }

            .header-body {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 18px;
            }

            .modal-title {
                font-size: 24px;
                font-weight: 700;
                font-family: 'Montserrat';
            }
        }
    }
}

.table-uni {
    width: 100%;

    thead, tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
    }

    tr {
        border-bottom: 1px solid #E6ECF6;
    }

    thead {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        tr {
            height: 68px;
        }

        td {
            svg {
                margin-left: 5px;
                cursor: pointer;
            }
        }
    }

    tbody {
        font-size: 14px;
        font-weight: 500;
        font-family: 'Montserrat';
        display: block;
        height: 280px;
        overflow: auto;

        tr {
            height: 60px;
        }
    }

    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-track {
        background-color: #D9D9D9D9;
        box-shadow: inset 0 0 5px #a3a3a3;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background: #656565;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #656565;
    }
}

.dark {
    .table-uni {
        ::-webkit-scrollbar-track {
            background-color: $c-dark-blue;
            box-shadow: inset 0 0 5px $c-dark-blue;
        }
    }
}

.grecaptcha-badge {
    display: none;
}

@media screen and (max-width: 770px) {
    .modal-content {
        margin-top: 100px;
    }
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    background-color: $c-primary !important;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    color: $c-light-grey !important;
}

.m-umd {
    margin: 15px;
}

.m-umd-t {
    margin-top: 15px;
}

.m-umd-b {
    margin-bottom: 15px;
}

.m-umd-l {
    margin-left: 15px;
}

.m-umd-r {
    margin-right: 15px;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    background-color: $c-primary !important;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    color: $c-light-grey !important;
}



.searchWrapper {
    border-radius: 6px !important;
    background-color: white;
    border-color: #dee2e6 !important;
    font-size: 14px;

    input {
        height: 20px;
    }

    .chip {
        background-color: $c-primary !important;
        font-size: 12px !important;
    }
}

.disable_ms {
    .optionListContainer {
        visibility: hidden;
    }
}

.multiSelectContainer {
    .singleChip {
        color: white;
    }

    .icon_down_dir {
        width: 12px;
    }
    
    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-track {
        background-color: #D9D9D9D9;
        box-shadow: inset 0 0 5px #a3a3a3; 
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background: #656565; 
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #656565; 
    }

    max-width: 290px;
    min-width: 240px;
    .checkbox {
        min-width: 10px !important;

        &:checked {
            background-color: $c-primary !important;
        }
    }

    input[type='text'] {
        min-width: 120px !important;
        width: 120px;
    }

    .highlightOption {
        background-color: $c-white !important;
        color: black !important;
    }

    li {
        font-size: 12px;
        display: flex;
        align-items: center;
        height: fit-content;
        min-height: 30px;
        max-height: 45px;
        padding: 0px 10px !important;

        &:hover {
            background-color: $c-primary !important;
            color: white !important;
        }
    }
}

.optionListContainer {
    z-index: 100 !important;

    .selected {
        input[type='checkbox'] {
            accent-color: $c-drakness-green
        }
    }
}

.dark {
    .searchWrapper {
        background-color: $c-dark-blue;
        border-color: $c-dark-blue !important;
    }
    
    .multiSelectContainer {
        ::-webkit-scrollbar-track {
            background-color: $c-dark-blue;
            box-shadow: inset 0 0 0px $c-dark-blue; 
        }

        max-width: 290px;
        min-width: 240px;
        .checkbox {
            min-width: 10px !important;
    
            &:checked {
                background-color: $c-primary !important;
            }
        }
    
        input[type='text'] {
            min-width: 120px !important;
            width: 120px;
        }
    
        .highlightOption {
            background-color: $c-dark-blue !important;
            color: white !important;
        }

        ul {
            border: 1px solid $c-dark-blue;
        }
    
        li {
            font-size: 12px;
            display: flex;
            align-items: center;
            height: fit-content;
            min-height: 30px;
            max-height: 45px;
            padding: 0px 10px !important;
            background-color: $c-dark-blue !important;
    
            &:hover {
                background-color: $c-drak-secundary-green !important;
                color: white !important;
            }
        }
    }
    
    .optionListContainer {
        .selected {
            input[type='checkbox'] {
                accent-color: $c-primary
            }
        }
    }
}