@import '../../variables.scss';

input {
    height: 40px;
    border-radius: 8px;
}

label {
    color: $c-primary-grey;
    font-size: 14px;
    font-weight: 500;
}

.select {
    display: flex;
    flex-direction: column;
}

.dark {
    .spinner-border {
        color: $c-white;
        height: 1.2rem;
        width: 1.2rem;
    }
}

.spinner-border {
    color: $c-primary;
    height: 1.2rem;
    width: 1.2rem;
}

.spinner-wrapper {
    min-width: 290px;
}

.rbt-aux .btn-close {
    color: transparent;
    position: relative;top:-30px;left: 760px;
}