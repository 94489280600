@import '../../variables.scss';

.dark {
    .pagination{
        color: white;
    
        .pages {
            p {
                &.active {
                    color: $c-strong-green;
                    text-decoration: underline;
                }
        
                &:hover {
                    text-decoration: underline;
                }
            }
        
            svg {
                color: $c-strong-green;
            } 
        }
    }
}
.pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 45px 20px 45px;
    color: $c-black;
    font-size: 14px;
    
    p {
        margin: 0px;
    }

    .pages {
        align-items: center;

        p {
            cursor: pointer;
            margin-right: 25px;
            font-weight: 600;

            &.active {
                color: $c-primary;
                text-decoration: underline;
            }

            &:hover {
                text-decoration: underline;
            }
        }

        svg {
            color: $c-primary;
            margin-right: 10px;
            cursor: pointer;
            height: 12px;
        }        
    }

    .take {
        align-items: center;
        font-size: 14px;
        margin-left: 50px;
        
        p {
            margin-bottom: 10px;
            margin-right: 20px;
        }
    }
}

@media screen and (max-width: 770px) { 
    .pagination {
        font-size: 12px;
        
        .take {
            display: none !important;
            
            p {
                margin-bottom: 10px;
                margin-right: 20px;
            }
        }
    }
} 