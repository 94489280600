@import '../../variables.scss';

.dark {
    .ton-product-wrapper {
        .select-year {
            .form-select, option {
                background-color: $c-dark-blue !important;
                color: $c-white;
            }
        }

        .ton-product-title {
            span {
                color: #D0D0D0;
            }
    
            .product-title {
                color: $c-white;
            }
        }

        .ton-graph-wrapper {
            .currentMonth {
                color: $c-strong-green;
            }
        }
    }
}

.ton-product-wrapper {
    padding: 20px 20px;
    p {
        margin: 0px;
    }

    .select-year {
        font-size: 12px !important;
        font-weight: 600;
        
        .form-select, option {
            cursor: pointer;
            font-size: 12px !important;
            font-weight: 600;
            background-color: #F2EFFF !important;
            border: none;
            padding: 0.5rem 2.60rem 0.5rem 1rem;
        }
    }

    .ton-product-title {
        font-size: 14px;
        font-weight: 600;
        align-items: center;


        span {
            color: #D0D0D0;
        }

        .product-title {
            color: #5A5A5A;
            font-size: 12px;
            font-weight: 600;
        }
    }

    .product-graph-wrapper {
        height: 140px;
        align-items: flex-end;
    }

    .ton-graph-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 11px;
        font-weight: 600;
        .ton {
            font-size: 12px;
            font-weight: 600;
        }

        .currentMonth {
            color: $c-primary;
        }

        .ton-graph {
            background-color: $c-strong-green;
            min-height: 35px;
            max-height: 100px;
            width: 28px;
            margin: 0px 6px;
            border-radius: 10px;
        }
    }
}

@media screen and (max-width: $b-mlg) and (min-width: $b-lg){  
    .ton-product-wrapper .product-graph-wrapper {
        height: 180px;
    }
}

@media screen and (max-width: $b-sm) {  
    .ton-product-wrapper .ton-graph-wrapper .ton-graph {
        max-width: 22px;
        width: 100%;
    }
    .ton-product-wrapper .product-graph-wrapper {
        gap: 5px;
        height: 150px;
    }

    .ton-product-wrapper .ton-graph-wrapper {
        max-height: 150px;
    }

    .ton-product-wrapper .ton-product-title {
        font-size: 13px;

        .product-title {
            font-size: 12px;
        }
    }
}

@media screen and (max-width: 700px) {  
    .ton-product-wrapper {
        .ton-product-title {
            font-size: 12px;

            .product-title {
                font-size: 10px;
            }
        }
        .ton-wrapper-filter {
            flex-direction: column !important;

            .select-year {
                width: 40%;
            }
        }
    }
}