@import '../../variables.scss';

.tracking {
    display: flex;
    flex-direction: column;
    .track-status {
        margin: 25px 0px;
        height: 14px;
        // background-color: #ACB9C9;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .tracking-item {
            width: 36px;
            height: 36px;
            border-radius: 50px;
            background-color: #ACB9C9;
            display: flex;
            align-items: center;
            justify-content: center;

            &.active {
                svg {
                    color: $c-white;
                    display: block;
                }    
            }

            &.old {
                background-color: $c-strong-green;
            }
            
            svg {
                display: none;
            }
        }
    }

    .status-names {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        font-weight: 600;
        font-family: 'Montserrat';
        margin-top: -10px;

        .idx-3 {
            margin-left: -30px;
        }

        .idx-4 {
            margin-left: 0px;
        }

        .idx-6 {
            margin-right: -20px;
        }

        span {
            font-size: 10px;
            font-weight: 500;
        }
    }
}

@media screen and (max-width: 770px) {  
    
    .tracking {
        .status-names {
            display: flex;
            justify-content: space-between;
            font-size: 10px;
            font-weight: 600;
            font-family: 'Montserrat';
            margin-top: -10px;

            div {
                font-size: 10px;
            }

            .idx-3 {
                margin-left: 0px;
            }

            .idx-4 {
                margin-left: 0px;
            }

            .idx-6 {
                margin-right: 0px;
            }

            span {
                font-size: 9px;
                font-weight: 500;
            }
        }
    }
}