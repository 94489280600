@import '../../variables.scss';

.dark {
    .modal-content {
        background-color: $c-dark-blue;
    }    
}

.modal-dialog .modal-content {
    padding-bottom: 20px;
}

.btn-secondary {
    border: 1px solid $c-primary;
}

.modal-dialog {
    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-track {
        background-color: #D9D9D9D9;
        box-shadow: inset 0 0 5px #a3a3a3;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background: #656565;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #656565;
    }
}