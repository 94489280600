@import '../variables.scss';

.dark{
    .bulk-edit-orders-wrapper {
        input, select {
            background-color: $c-dark-blue;
            color: $c-white;
            border-color: $c-primary-grey;
        }

        background-color: $c-drak-secundary-green;
        .bulk-edit-orders-header{
            background-color: $c-dark-blue;
        }
    }

    .react-calendar__month-view__days__day--weekend{
        color: red !important;
    }


    .table-orders{
        tbody{
            tr{
                &:nth-child(even) {
                    background-color: $c-table-alternate-line-color;
                    .accordion-button {
                        background-color: $c-table-alternate-line-color;
                        color: $c-white;



                }
            }
            }
        }

        .date{
            input{
                background-color: $c-dark-blue;
            }

            .react-date-picker__wrapper{
                button{
                    color: $c-white;
                    border-radius: 0px 6px 6px 0px;
                    background-color: $c-dark-blue;
                }
            }

            .react-date-picker{
               .react-date-picker__calendar {
                    .react-calendar__tile--now {
                        background: $c-strong-green;
                    }
                }

                .react-date-picker__inputGroup{
                    background-color: $c-dark-blue;
                    border-radius: 6px 0px 0px 6px;
                }
            }
        }
    }

    .error{
        input{
            border: 1px solid red
        }
    }

    .integration-success{
        input, select {
            border: 1px solid $c-primary;
        }
    }

    // .integration-success-data{
    //     border: 1px solid #027948;
    //     border-radius: 10px;
    // }

    .back-btn{
        color:$c-white;
        border: 1px solid $c-strong-green;
    }

    .react-calendar{
        background-color: $c-dark-blue;

        .react-calendar__navigation__arrow {
        .react-calendar__navigation__prev-button{
                color: $c-strong-green;
            }
        }
    }

    .react-calendar__tile{
        color: $c-white;
    }
    .react-calendar__navigation__label{
        color:$c-white;
    }


}


.bulk-edit-orders-wrapper {
    width: 100%;
    max-width: 1310px;
    margin-right: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $c-white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0px 8px 16px 0px rgba(73, 73, 73, 0.06);

    .bulk-edit-orders-header {
        background-color: $c-primary;
        height: 54px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 20px;
    }

    .bulk-edit-orders-header-i {
        color: $c-strong-green;
        border-radius: 10px 10px 0px 0px;
        display: flex;
        align-items: center;

        img {
            width: 60px;
            margin-top: 5px;
        }

        p {
            font-weight: 600;
            font-size: 18px;
            font-family: 'Montserrat';
            margin: 0px;
            margin-left: 40px;
            width: 70px;
            line-height: 18px;
        }
    }

    .button-wrapper{
        display: flex;
        gap:20px;
        align-items: center;
        justify-content: flex-end;
        padding: 60px;
    }

    .button-secundary{
        background-color: $c-white;
        border-style: solid $c-primary;
        color: $c-primary;
    }
}

.table-orders {
    //max-width: 1230px;
    width: 100%;
    margin: 30px 0px;
    overflow-x: auto;
    display: block;
    min-height: 350px;

    .small-td{
        width: 77% !important;
    }

    .date{
        .react-date-picker__wrapper{
            button{
                color: $c-black;
                border-radius: 0px 6px 6px 0px;
                background-color: $c-white;
            }
        }

        .react-date-picker__inputGroup{
            background-color: $c-white;
            border-radius: 6px 0px 0px 6px;
        }
    }


    tbody {
        height: 100%;
        min-height: 280px;
        font-size: 13px;
        font-family: inherit;

        .actions .action-icon{
            width: 150px;
            height: 35px;
            border: none !important;
            padding: 10px;
            cursor: pointer;
            width: 100%;
            display: flex;
            gap: 5px;

            svg {
                color: $c-primary;
                height: 16px;
            }

            &:hover {
                background-color: #e3e2e2;
            }
        }

        .item-header {
            display: flex;
            align-items: center;
            font-size:12px;

            div {
                max-width: 300px;
                margin-bottom: auto !important;

                select, option, input{
                    font-size: 12px !important;
                    height: 38px !important;
                }
            }
        }

        tr {
            min-height: 60px;
            height: fit-content;
            display: flex;
            flex-direction: row;
            padding: 14px 14px;

            &:nth-child(even) {
                background-color: #F3F5F2;
                .accordion-button {
                    background-color: #F3F5F2;
                }
            }

            td {
                width: 100%;
                margin-right: 8px;

                &:nth-child(1) {
                    padding-left: 10px;
                }

                .dropdown {
                    button {
                        height: 30px;
                        display: flex;
                        align-items: center;
                    }
                }

                .dropdown-toggle::after {
                    display: none;
                }
            }
        }
    }

    .date-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 16px;
    }

    .date-wrapper-ar {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 35px;
    }

    .date {
        width: 220px;

        input {
            border-radius: 0px;
            border: none;
            width: 20px !important;
            height: 38px;
            text-align: center;
            background-color: $c-white;

            &:last-child {
                width: 40px !important;
            }
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type=number] {
            -moz-appearance: textfield;
            &:focus-visible {
                outline: none;
            }
        }

        .react-date-picker {
            border: 1px solid #dee2e6;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 40px;

            .react-date-picker__calendar--closed {
                display: none;
            }

            .react-date-picker__calendar {
                font-family: 'Montserrat';
                width: 300px;
                z-index: 1000;

                abbr {
                    cursor: auto;
                    text-decoration: none;
                }

                .react-calendar__navigation button:disabled {
                    color: $c-black;
                    background-color: transparent;
                }

                .react-calendar__navigation__next2-button {
                    display: none;
                }

                .react-calendar__navigation__prev2-button {
                    display: none;
                }

                .react-calendar__tile--active {
                    background-color: $c-primary !important;
                }

                .react-calendar__tile {
                    border-radius: 10px;

                    abbr {
                        cursor: pointer;
                    }
                }

                .react-calendar__tile--now {
                    background: $c-light-green-secondary;
                }

                .react-calendar__navigation button {
                    font-size: 16px;
                    text-transform: capitalize;
                    font-weight: 600;
                    font-family: 'Montserrat';
                }

                .react-calendar__navigation__prev-button {
                    font-size: 22px !important;
                    color: $c-primary;
                }

                .react-calendar__navigation__next-button {
                    font-size: 22px !important;
                    color: $c-primary;
                }
            }

            .react-date-picker__inputGroup {
                width: 100%;
                text-align: center;
            }
        }

        .react-date-picker__wrapper {
            display: flex;
            width: 100%;
            justify-content: flex-end;

            button {
                border: none;
            }
        }

    }

    .table-status {
        display: flex;
        align-items: center;
        padding: 4px 10px;
        width: fit-content;
        font-size: 14px;
        border-radius: 8px;

        .table-status-icon {
            width: 14px;
            height: 14px;
            border-radius: 50px;
            margin-right: 10px;
        }
    }

    .accordion {
        box-shadow: 0px 8px 16px 0px rgba(73, 73, 73, 0.06);

        .accordion-item:first-of-type {
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
        }
    }

    .accordion-item {
        padding-top: 10px;
        font-size: 14px;
        min-height: 75px;

        &:nth-child(even) {
            background-color: #F3F5F2;
            .accordion-button {
                background-color: #F3F5F2;
            }
        }

        .item-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size:12px;

            div {
                min-width: 90px;
                text-align: center;
                max-width: 150px;
            }

            .form-check-input {
                width: 18px;
                height: 18px;

                &:checked {
                    background-color: $c-primary;
                }

                &:focus {
                    box-shadow: 0 0 0 0.25rem $c-light-green-secondary;
                }
            }

            button {
                width: 115px;
                border-color: $c-green;
                color: $c-green;
                margin-right: 10px;
                height: 35px;
                font-size: 11px;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                    background-color: $c-light-green-secondary;
                }
            }
        }

        button {
            width: 50px;
        }
    }

    .table-header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        background-color: $c-green;
        color: $c-strong-green;
        font-size: 12px;
        height: 40px;
        align-items: center;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        font-family: inherit;
        text-transform: capitalize;
        font-weight: normal;
        min-width: 1310px;

        td {
            width: 100%;
            margin-right: 8px;
        }

        .form-check-input {
            width: 18px;
            height: 18px;

            &:checked {
                background-color: $c-green;
            }

            &:focus {
                box-shadow: 0 0 0 0.25rem $c-light-green-secondary;
            }
        }

        &:first-child {
            padding-left: 25px;
        }

        div {
            width: 70px;
            text-align: center;
        }

        .check-header {
            width: 50px;
            background: $c-primary;
            height: 100%;
            border-top-left-radius: 9px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        svg {
            margin-left: 10px;
        }
    }
}

.error{
    input{
        border: 1px solid red
    }
}

.integration-success{
    input, select {
        border: 1px solid $c-primary;
    }
}

.integration-success-data{
    border: 2px solid #027948;
    border-radius: 8px;
}

.back-btn{

    border: 1px solid $c-primary;
}

.table-orders::-webkit-scrollbar {
    height: 8px;
}

.table-orders::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.table-orders::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
    border: 2px solid #f1f1f1;
}

.table-orders::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.weight-alert{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3px;
}