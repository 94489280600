@import '../../variables.scss';

.dark menu{
    background-color: $c-dark-green;
}

.extend {
    width: 282px !important;
    padding-left: 0px;
    transition: all .2s;

    .menu-item {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        margin-bottom: 40px;
        width: 100%;
        padding-left: 10px;

        img {
            margin-right: 15px;
        }

        span {
            color: $c-strong-green;
            font-weight: 600;
            font-size: 16px;
        }

        &-on-center {
            height: 50px;
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-bottom: 40px;
            width: 100%;
            padding-left: 10px;
            justify-content: center;
        }

        &:hover {
            background-color: $c-drakness-green;
        }
    }

    .item-active {
        border-left: 1px solid $c-strong-green;
        background-color: $c-drakness-green;
    }
}

menu {
    width: 72px;
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    height: 100vh;
    background-color: $c-green;
    margin-top: 0px;
    padding-left: 0px;
    box-shadow: 0px 8px 16px 4px rgba(0, 0, 0, 0.2);
    z-index: 1049;

    .menu-item {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-bottom: 40px;

    }

    .item-active {
        border-left: 1px solid $c-strong-green;

        .menu-icon {
            background-color: $c-drakness-green;
            padding: 10px;
            border-radius: 8px;
        }
    }

}

@media screen and (max-width: $b-sm) { 
    .extend {
        height: 100vh !important;

        .menu-item-on-center {
            img {
                display: none;
            }

            .logo-mobile {
                display: block;
            }

            svg {
                height: 16px;
                color: $c-strong-green;
            }
        }
    }

    menu {
        width: 72px;
        height: fit-content;
        margin-bottom: 0px;


        .menu-item {
            &:nth-child(1) {
                display: flex;  
                margin-bottom: 20px;              
            }
            display: none;
        }
    }
  }