@import '../../variables.scss';


.modal-detail-order { 
    .dark-modal-content {
        background-color: $c-dark-green;
        color: $c-white;

        .btn-close {
            color: $c-white;
        }
        
        .label {
            color: $c-white !important;
        }

        .css-t3ipsp-control, .css-13cymwt-control {
            background-color: $c-dark-blue;
            color: white;

            .css-1dimb5e-singleValue, .css-1jqq78o-placeholder {
                color: $c-white;
            }
        }

        .css-1nmdiq5-menu {
            background-color: $c-dark-green;
        }

        .css-d7l1ni-option, .css-1p3m7a8-multiValue {
            background-color: $c-primary !important;

            .css-wsp0cs-MultiValueGeneric {
                color: $c-white;
            }
        }
    }

    .modal-content {
        width: 800px;
        min-height: 400px;
    }

    .divider {
        border-bottom: 1px solid $c-strong-green;
        width: 80%;
        padding: 0px 40px;
        margin: 0 auto;
    }

    .body {
        display: flex;
        font-size: 14px;
        height: 300px;
        text-align: left;
        margin-top: 20px;

        .right-body {
            display: flex;
            flex-direction: column;
            padding-right: 25px;
            justify-content: flex-start;
        }

        .left-body {
            border-right: 1px solid $c-light-grey;
            padding-right: 20px;
            margin-right: 20px;
            width: 280px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 20px;

            .order-number {
                font-size: 26px;
            }
        }

        .m-bottom {
            margin-bottom: 30px;
        }

        .label-modal {
            font-size: 12px;
        }
    }
}

@media screen and (max-width: 770px) { 
    .modal-detail-order { 
        .dark-modal-content {
            background-color: $c-dark-green;
            color: $c-white;

            .btn-close {
                color: $c-white;
            }
            
            .label {
                color: $c-white !important;
            }

            .css-t3ipsp-control, .css-13cymwt-control {
                background-color: $c-dark-blue;
                color: white;

                .css-1dimb5e-singleValue, .css-1jqq78o-placeholder {
                    color: $c-white;
                }
            }

            .css-1nmdiq5-menu {
                background-color: $c-dark-green;
            }

            .css-d7l1ni-option, .css-1p3m7a8-multiValue {
                background-color: $c-primary !important;

                .css-wsp0cs-MultiValueGeneric {
                    color: $c-white;
                }
            }
        }

        .modal-content {
            width: 100%;
            min-height: 400px;
        }

        .divider {
            border-bottom: 1px solid $c-strong-green;
            width: 80%;
            padding: 0px 40px;
            margin: 0 auto;
        }

        .body {
            display: flex;
            flex-direction: column;
            font-size: 12px;
            height: 300px;
            text-align: left;
            margin-top: 20px;

            .right-body {
                display: flex;
                flex-direction: column;
                padding-right: 25px;
                justify-content: flex-start;
            }

            .left-body {
                border-right: none;
                padding-right: 0px;
                margin-right: 0px;
                width: 280px;
                display: flex;
                flex-direction: row;
                gap: 10px;
                justify-content: center;
                padding-left: 20px;

                .order-number {
                    font-size: 14px;
                }
            }

            .m-bottom {
                margin-bottom: 30px;
            }

            .label-modal {
                font-size: 10px;
            }
        }
    }
}