@import '../variables.scss';

.dark .wrapper {
  background-color: $c-drak-secundary-green;
}

.wrapper {
  padding-left: 110px;
  padding-top: 10px;
  width: 100%;
  background-color: $c-content-primary;
  padding-bottom: 60px;
  min-height: 80vh;
  
  @media screen and (min-width: 1650px) {
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: $b-sm) {  
  .wrapper {
    padding: 70px 20px 20px 20px;
  }
}
