@import '../variables.scss';

.dark {
    .call-center-view-wrapper {
        background-color: $c-dark-green;        

        .form-title-left, .form-title-right {
            color: $c-white;
            border-bottom: 1px solid $c-dark-blue;
        }

        .divider {
            border-bottom: 1px solid $c-dark-blue;
        }

        input, select, textarea {            
            &:focus {
                border-color: $c-green;
                box-shadow: 0 0 0 0.25rem $c-green;
            }
        }

        input {
            color: $c-white;
            background-color: $c-dark-green;
        }

        label {
            color: $c-white !important;
        }

        select {
            color: $c-white;
            background-color: $c-dark-green;
            
            option:hover {
                background-color: $c-green !important;
            }
        }

        textarea {
            background-color: $c-dark-green;
            color: $c-white;
        }

        .call-center-view-header {
            background-color: $c-dark-blue;
        }

        .first-column {
            border-right: 1px solid $c-dark-blue;
        }

        .status {
            background-color: #091B1B !important;
        }

        .served-warning-message {
            background-color: $c-dark-blue;
            color: white;
        }

        .history {            
            .history-wrapper {                
                .history-body {
                    &.is-unipar {
                        background-color: $c-dark-blue;
                    }
                }
            }
        }

        .button-reply, .button-finish {
            background-color: $c-dark-green;
            color: #027948;
        
            &:hover {
                background-color: $c-dark-blue;
            }
        }
    }
}

.call-center-view-wrapper {
    width: 100%;
    max-width: 1310px;
    margin-right: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $c-white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0px 8px 16px 0px rgba(73, 73, 73, 0.06);

    ::placeholder {
        color: #ABABAB;
    }

    input, textarea, select {
        &:focus {
            border-color: $c-light-green-secondary;
            box-shadow: 0 0 0 0.25rem $c-light-green-secondary;
        }
    }

    select {
        border-radius: 8px;
        font-size: 14px;
        color: $c-black;
        width: 305px;
        
        option:hover {
            background-color: $c-green !important;
        }
    }

    label {
        font-size: 14px;
        font-weight: 500;
        color: #666666;
    }

    .call-center-view-header {
        background-color: #065E39;
        height: 54px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 40px;

        .call-center-view-header-i {
            color: $c-strong-green;
            border-radius: 10px 10px 0px 0px;
            display: flex;
            align-items: center;

            img {
                width: 60px;
                margin-top: 5px;
            }

            p {
                font-weight: 600;
                font-size: 18px;
                font-family: 'Montserrat';
                margin: 0px;
                margin-left: 40px;
                width: 70px;
                line-height: 18px;
            }
        }        
    }

    .form-title-left, .form-title-right {
        color: #444444;
        font-size: 20px;
        font-weight: 600;
        font-family: 'Montserrat';
        border-bottom: 1px solid #E4ECF4;
        padding-bottom: 15px;
        margin-bottom: 0;
    }

    .first-column, .second-column {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 21px;
    }

    .form-control {
        font-size: 14px;
    }

    .body-wrapper {
        padding: 30px 52px;
    }

    .divider {
        border-bottom: 1px solid #E4ECF4;
        width: 100%;
    }

    .status {
        display: flex;
        align-items: center;
        padding: 4px 10px;
        width: fit-content;
        font-size: 13px;
        border-radius: 8px;
        margin-left: 0;
        min-width: fit-content !important;
    }

    .status-icon {
        width: 15px;
        height: 12px;
        border-radius: 50px;
        margin-right: 10px;
    }

    .row-reverse {
        flex-direction: column-reverse;
        gap: 20px;        
    }

    .padded-block-right {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .served-warning-message {
        display: flex;
        gap: 10px;
        background-color: #F2F2F9FC;
        border-radius: 15px;
        padding: 11px;
        margin-bottom: 30px;
        font-size: 14px;
        font-weight: 600;
        color: #ed3524;
    }

    .property-value {
        font-weight: 500;
        font-size: 14px;
    }

    .history {
        .divider {
            margin-top: 20px;
            margin-bottom: 20px;
        }

        .history-wrapper {
            display: flex;
            align-items: flex-start;
    
            .history-body {
                display: flex;            
                flex-direction: column;
                padding: 11px 10px;
                width: 100%;

                .history-header {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 4px;

                    .history-title {
                        color: #027948;
                    }

                    .history-date {
                        color: #666666;
                    }

                    .history-title, .history-date {
                        font-family: 'Open Sans';
                        font-size: 14px;
                        font-weight: 600;
                    }
                }

                &.is-unipar {
                    background-color: #F6FFF8;

                    .history-description {
                        font-weight: 500;
                    }
                }
            }
        }
    }

    .buttons-wrapper {
        display: flex;
        justify-content: flex-end;
        gap: 30px;
        margin-top: 30px;
    }

    .button-reply, .button-finish {
        width: 246px;
        font-family: 'Open Sans';
        font-weight: 700;
        font-size: 16px;
        border-radius: 4px;
    }

    .button-reply {
        border: 2px dashed #065E39;
        background-color: #fff;
        color: #065E39; 
        padding: 9px 48px;

        &:hover {
            background-color: #CCFFD8;
        }
    }

    .button-finish {
        border: 2px solid #065E39;
        background-color: #065E39;
        color: #FFFFFF;
        padding: 9px 30px;
        
        &:hover {
            background-color: #027344;
        }
    
        .spinner-border {
            color: #fff;
        } 
    }

    .button-send {
        width: 172px;
        font-family: 'Open Sans';
        font-weight: 700;
        font-size: 16px;
        border-radius: 4px;
        border: 2px solid #065E39;
        background-color: #065E39;
        color: #FFFFFF;
        padding: 9px 30px;
        
        &:hover {
            background-color: #027344;
        }
    
        .spinner-border {
            color: #fff;
        } 
    }

    .message {
        margin-top: 30px;
        margin-right: 0;
        margin-left: 70px;
    }

    .error-mandatory {
        margin-top: 10px;
        margin-left: 70px;
    }

    @media screen and (min-width: 768px) {
        .first-column {
            border-right: 1px solid #E4ECF4;
        }

        .form-title-right {
            padding-left: 40px;
        }
    
        .second-column {
            padding-left: 40px;
        }
        
        .row-reverse {
            flex-direction: initial;
            gap: initial;        
        }

        .padded-block-left {
            padding-right: 40px;
        }

        .padded-block-right {
            padding-left: 40px;
        }
    }
}