@import '../variables.scss';

.link-ga {
    text-decoration: 'none';
}

.dark {
    .financial-wrapper {
        background-color: $c-dark-green;

        .buttons-selecteds {
            color: $c-dark-green;
            .btn-selected-disabled {
                background-color: $c-strong-green;
            }
        }

        .button-icon {
            background-color: $c-dark-green;
            color:  $c-white;

            &:hover {
                background-color: $c-dark-blue;
            }
        }

        .button-icon-disabled {
            background-color: $c-dark-blue;
            border: 1px solid $c-dark-blue;
        }

        input, select {
            background-color: $c-dark-blue;
            color: $c-white;
            border-color: $c-black;
        }

        .my-orders-search {
            background-color: $c-dark-green;

            .react-date-picker {
                border: 1px solid $c-label;
                .react-date-picker__calendar {
                    .react-calendar__navigation button:disabled {
                        color: $c-black;
                    }

                    .react-calendar__navigation button {
                        color: $c-white;

                        &:hover {
                            background-color: transparent;
                        }
                    }

                    .react-calendar {
                        background-color: $c-dark-blue;
                    }

                    .react-calendar__tile {
                        color: $c-white;

                        &:hover {
                            background-color: black;
                        }
                    }

                    .react-calendar__tile--active {
                        background-color: $c-primary !important;
                    }

                    .react-calendar__tile--now {
                        background: $c-label;
                    }

                    .react-calendar__navigation__prev-button {
                        color: $c-strong-green;
                    }

                    .react-calendar__navigation__next-button {
                        color: $c-strong-green;
                    }
                }

                .react-date-picker__wrapper {
                    button {
                        color: $c-white;
                    }
                }
            }

            .react-date-picker {
                background-color: $c-dark-blue;

                .react-date-picker__inputGroup input {
                    height: 35px;
                }
            }

            .react-date-picker__wrapper button {
                color: $c-white;
            }
        }

        .my-orders-header {
            background-color: $c-dark-blue;
        }

        .order-body {
            background-color: $c-dark-blue;
        }

        .table-uni {
            tbody{
                overflow: auto;
            }
            tbody tr {
                &:nth-child(even) {
                    background-color: $c-dark-green;
                }
            }

            .accordion {
                box-shadow: 0px 8px 16px 0px rgba(73, 73, 73, 0.06);
            }
    
            .accordion-item {
                background-color: $c-dark-green;
                color: $c-white;
                border-color: $c-dark-blue;

                .accordion-button {
                    background-color: $c-dark-green;;
                    color: $c-white;

                    &::after {
                        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
                    }
                }
    
                &:nth-child(even) {
                    background-color: #072d2d;
                    .accordion-button {
                        background-color: #072d2d;
                        color: $c-white;
                    }
                }
    
                .item-header {
                    .form-check-input {
                        &:checked {
                            background-color: $c-primary;
                        }
    
                        &:focus {
                            box-shadow: 0 0 0 0.25rem $c-light-green-secondary;
                        }
                    }
                }
            }
    
            .accordion-body {
                background-color: $c-dark-blue;
                color: $c-white;
                border-top: 1px solid $c-dark-blue;
                .item-body {
                    .item-body-actions {
                        .order-butons {
                            .button-icon {
                                background-color: $c-dark-green;
                                color:  $c-white;
                            }

                            .button-icon-disabled {
                                background-color: $c-dark-blue;
                                border: 1px solid $c-dark-blue;
                            }
                        }
    
                        .btn-outlined {
                            color: $c-strong-green;
                            border: 2px solid $c-strong-green;

                            &:hover {
                                background-color: $c-dark-green;
                            }
                        }
                    }
    
                    label {
                        color: #dcdc;
                    }
    
                    p {
                        color: $c-light-grey;
                    }
                }
            }
    
            .accordion-button {
                background-color: $c-white;
                &:focus {
                    border-color: $c-light-green-secondary;
                }
            }
    
            .table-header {
                background-color: $c-green;
                color: $c-strong-green;
                .form-check-input {
                    &:checked {
                        background-color: $c-green;
                    }
    
                    &:focus {
                        box-shadow: 0 0 0 0.25rem $c-light-green-secondary;
                    }
                }
    
                .check-header {
                    background: $c-primary;
                }
            }
        }
    }
}

.financial-wrapper {
    width: 100%;
    max-width: 1310px;
    margin-right: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $c-white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0px 8px 16px 0px rgba(73, 73, 73, 0.06);

    .button-icon {
        height: 25px;
        width: 100%;
        background-color: $c-white;
        color:  $c-black;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 15px;
        font-size: 12px;
        cursor: pointer;
        gap: 10px;

        a {
            text-decoration: none;
            color: $c-black;
        }

        &:hover {
            background-color: $c-light-grey;
        }

        svg {
            height: 18px;
            color: $c-primary;
        }
    }

    .button-icon-disabled {
        background-color: $c-white;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #BCBCBC;
        border: 1px solid #BCBCBC;
        border-radius: 8px;
        margin-right: 8px;

        svg {
            width: 12px;
        }
    }

    .btns-wapper {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }

    .buttons-selecteds {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 6px;
        margin-bottom: 10px;
        padding-left: 2px;
        font-size: 14px;

        .btn-selected {
            cursor: pointer;
            
            padding: 5px;
            padding-left: 8px;
            padding-right: 8px;
            border-radius: 10px;
            background-color: $c-strong-green;

            display: flex;
            align-items: center;
            gap: 8px;

            svg {
                color: $c-green;
            }
        }

        .btn-selected-disabled {
            cursor: not-allowed;

            padding: 5px;
            opacity: 0.7;
            padding-left: 8px;
            padding-right: 8px;
            border-radius: 10px;
            background-color: $c-light-green;

            display: flex;
            align-items: center;
            gap: 8px;

            svg {
                color: $c-green;
            }
        }
    }

    .my-orders-header {
        background-color: #065E39;
        height: 54px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .my-orders-search {
        height: 100px;
        background-color: $c-primary;
        
    }

    .table-uni {
        max-width: 1230px;
        width: 100%;
        margin: 20px 0px;

        table {
            width: 100%;

            .table-status {
                min-width: 120px;

                .table-status-icon {
                    width: 18px;
                }
            }
        }

        tbody {
            font-family: inherit;
            height: fit-content !important;
            min-height: 300px;
            overflow: auto;
            max-height: 380px;
        }

        tbody tr {
            &:nth-child(even) {
                background-color: #F3F5F2;
            }

            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .td-check {
                width: 250px;
                margin-right: 10px;
                
                div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            td {
                width: 100%;

                .dropdown {
                    .btn {
                        height: 30px;
                        width: 40px;
                        align-items: center;
                        justify-content: center;
                        display: flex;
                    }
                }

                .dropdown-toggle::after {
                    display: none;
                }
            }
        }


        .accordion {
            box-shadow: 0px 8px 16px 0px rgba(73, 73, 73, 0.06);

            .accordion-item:first-of-type {
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
            }
        }

        .accordion-item {
            padding-top: 10px;
            font-size: 14px;
            min-height: 75px;

            &:nth-child(even) {
                background-color: #F3F5F2;
                .accordion-button {
                    background-color: #F3F5F2;
                }
            }

            .item-header {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .table-status-icon {
                    width: 15px;
                    height: 12px;
                    border-radius: 50px;
                    margin-right: 10px;
                }

                div:first-child {
                    margin-left: 18px;
                    min-width: fit-content !important;
                }

                div {
                    min-width: 120px;
                    text-align: center;
                }

                .form-check-input {
                    width: 18px;
                    height: 18px;
                    
                    &:checked {
                        background-color: $c-primary;
                    }

                    &:focus {
                        box-shadow: 0 0 0 0.25rem $c-light-green-secondary;
                    }
                }
            }

            button {
                width: 50px;
            }
        }

        .accordion-body {
            background-color: #F5FFF8;
            border-top: 1px solid #E0E0E0;
            padding: 30px 60px;

            .item-body {
                
                .item-body-labels {
                    display: flex;
                    justify-content: space-between;
                }

                .item-body-actions {
                    width: 20%;
                    margin-left: 80px;
                    .order-butons {
                        display: flex;
                        margin-top: 5px;

                        .button-icon {
                            height: 25px;
                            width: 100%;
                            background-color: $c-white;
                            color:  $c-black;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 15px;
                            font-size: 12px;
                            cursor: pointer;
                            gap: 10px;

                            &:hover {
                                background-color: $c-light-grey;
                            }

                            svg {
                                height: 18px;
                                color: $c-primary;
                            }
                        }

                        .button-icon-disabled {
                            background-color: $c-white;
                            width: 25px;
                            height: 25px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: #BCBCBC;
                            border: 1px solid #BCBCBC;
                            border-radius: 8px;
                            margin-right: 8px;
                    
                            svg {
                                width: 12px;
                            }
                        }
                    }

                    .btn-outlined {
                        color: #027948;
                        border: 2px solid #027948;
                        min-width: 130px !important;
                        font-weight: 600;
                        height: 35px;
                        padding: 3px 9px;
                        width: fit-content;

                        &:hover {
                            background-color: $c-light-green-secondary;
                        }
                    }
                }

                label {
                    color: #5A5A5A;
                    font-weight: 600;
                    font-size: 12px;
                }

                p {
                    font-size: 14px;
                    color: $c-black;
                    font-weight: 600;
                }
            }
        }

        .accordion-button {
            background-color: $c-white;
            width: 30px;
            box-shadow: none;

            &:focus {
                border: none;
                border-color: $c-light-green-secondary;
                box-shadow: none;
            }
        }

        .form-check-input:checked {
            background-color: $c-green !important;
        }

        .table-header {
            display: flex;
            width: 100%;
            background-color: $c-green;
            color: $c-strong-green;
            font-size: 12px;
            height: 40px;
            align-items: center;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            justify-content: space-between; 
            
            td {
                width: 100%;

                .dropdown {
                    .btn {
                        height: 30px;
                        width: 40px;
                        align-items: center;
                        justify-content: center;
                        display: flex;
                    }
                }

                .dropdown-toggle::after {
                    display: none;
                }
            }

            .form-check-input {
                width: 18px;
                height: 18px;
                
                &:checked {
                    background-color: $c-green;
                }

                &:focus {
                    box-shadow: 0 0 0 0.25rem $c-light-green-secondary;
                }
            }
            
            .check-header {
                width: 255px;
                background: $c-primary;
                height: 100%;
                border-top-left-radius: 9px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
            }            

            svg {
                margin-left: 10px;
            }
        }
    }


    .my-orders-search {
        display: flex;
        justify-content: flex-start;
        gap: 30px;
        padding: 20px 35px 0px 35px;
        
        .search-refresh {
            svg {
                cursor: pointer;
                color: $c-white;
                height: 20px;
            }
        }

        label {
            color: $c-white;
            font-weight: 600;
        }

        select {
            min-width: 250px;
        }

        input {
            min-width: 200px;
        }

        input[type=date] {
            border: none !important;
        }

        .date-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-bottom: 16px;

            input[type=date] {
                border: none !important;
            }

            input {
                min-width: 50px !important;
            }
        }

        .date {
            width: 220px;

            input {
                border-radius: 0px;
                border: none;
                width: 20px !important;
                height: 38px;
                text-align: center;
                background-color: $c-white;

                &:last-child {
                    width: 40px !important;
                }
            }

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            input[type=number] {
                -moz-appearance: textfield;
                &:focus-visible {
                    outline: none;
                }
            }

            .react-date-picker {
                border: 1px solid #dee2e6;
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 40px;
                background-color: $c-white;

                .react-date-picker__calendar--closed {
                    display: none;
                }

                .react-date-picker__calendar {
                    font-family: 'Montserrat';
                    width: 300px;
                    z-index: 1000;

                    abbr {
                        cursor: auto;
                        text-decoration: none;
                    }

                    .react-calendar__navigation button:disabled {
                        color: $c-black;
                        background-color: transparent;
                    }

                    .react-calendar__navigation__next2-button {
                        display: none;
                    }

                    .react-calendar__navigation__prev2-button {
                        display: none;
                    }

                    .react-calendar__tile--active {
                        background-color: $c-primary !important;
                    }

                    .react-calendar__tile {
                        border-radius: 10px;

                        abbr {
                            cursor: pointer;
                        }
                    }

                    .react-calendar__tile--now {
                        background: $c-light-green-secondary;
                    }

                    .react-calendar__navigation button {
                        font-size: 16px;
                        text-transform: capitalize;
                        font-weight: 600;
                        font-family: 'Montserrat';
                    }

                    .react-calendar__navigation__prev-button {
                        font-size: 22px !important;
                        color: $c-primary;
                    }

                    .react-calendar__navigation__next-button {
                        font-size: 22px !important;
                        color: $c-primary;
                    }
                }

                .react-date-picker__inputGroup {
                    width: 100%;
                    text-align: center;
                }
            }

            .react-date-picker__wrapper {
                display: flex;
                width: 100%;
                justify-content: space-between;
                
                button {
                    background-color: $c-white;
                    border: none;
                }
            }

        }

        .react-date-picker {
            border: 1px solid #dee2e6;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 40px;
            background-color: $c-white;

            .react-date-picker__calendar--closed {
                display: none;
            }

            .react-date-picker__calendar {
                font-family: 'Montserrat';
                width: 300px;
                z-index: 1000;

                abbr {
                    cursor: auto;
                    text-decoration: none;
                }

                .react-calendar__navigation button:disabled {
                    color: $c-black;
                    background-color: transparent;
                }

                .react-calendar__navigation__next2-button {
                    display: none;
                }

                .react-calendar__navigation__prev2-button {
                    display: none;
                }

                .react-calendar__tile--active {
                    background-color: $c-primary !important;
                }

                .react-calendar__tile {
                    border-radius: 10px;

                    abbr {
                        cursor: pointer;
                    }
                }

                .react-calendar__tile--now {
                    background: $c-light-green-secondary;
                }

                .react-calendar__navigation button {
                    font-size: 16px;
                    text-transform: capitalize;
                    font-weight: 600;
                    font-family: 'Montserrat';
                }

                .react-calendar__navigation__prev-button {
                    font-size: 22px !important;
                    color: $c-primary;
                }

                .react-calendar__navigation__next-button {
                    font-size: 22px !important;
                    color: $c-primary;
                }
            }

            .react-date-picker__inputGroup {
                width: 100%;
                text-align: center;
                display: flex;
                input {
                    min-width: 55px !important;
                    border: none;
                    text-align: center;
                }
            }

            .react-date-picker__inputGroup__divider {
                margin: 5px;
            }
        }

        .react-date-picker__wrapper {
            display: flex;
            width: 100%;
            justify-content: space-between;
            
            button {
                background-color: transparent;
                border: none;
            }
        }
    }
}

.my-orders-header {
    background-color: $c-primary;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 40px;

    .my-orders-header-i {
        color: $c-strong-green;
        border-radius: 10px 10px 0px 0px;
        display: flex;
        align-items: center;

        img {
            width: 60px;
            margin-top: 5px;
        }

        p {
            font-weight: 600;
            font-size: 18px;
            font-family: 'Montserrat';
            margin: 0px;
            margin-left: 40px;
            width: 70px;
            line-height: 18px;
        }
    }   

    .btn {
        background-color: $c-green;
        display: flex;
        align-items: center;
        font-weight: 600;
        border-radius: 20px;
        padding: 10px 20px;

        img {
            width: 20px;
            margin-right: 10px;
        }
    }
}

@media screen and (max-width: $b-lg) { 
    .financial-wrapper {
        margin-right: 0px;

        .my-orders-search {
            flex-wrap: wrap;
            padding: 15px 15px 0px 15px;
            height: fit-content;

            select {
                min-width: 200px;
            }
        }
    }
}

@media screen and (max-width: 1090px) { 
    .financial-wrapper {
        margin-right: 0px;

        .my-orders-search {
            flex-wrap: wrap;
            padding: 15px 15px 0px 15px;
            height: fit-content;
            .dates-inputs {
                flex-wrap: wrap;

                label {
                    font-size: 12px;
                }
            }
            select {
                min-width: 200px;
            }
        }

        .table-uni {
            .table-header {
                :nth-child(2) {
                    margin-left: 0px;
                }

                .header {
                    svg {
                        display: none;
                    }
                }
            }

            .accordion-item {
                .item-header {
                    div {
                        min-width: 80px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $b-sm) { 
    .financial-wrapper {
        margin-right: 0px;

        .my-orders-search {
            flex-wrap: wrap;
            padding: 15px 15px 0px 15px;
            height: fit-content;
            font-size: 12px;
            
            select {
                min-width: 200px;
                font-size: 12px;
            }

            input, label {
                font-size: 12px;
            }
        }

        .table-uni {
            .table-header {
                font-size: 10px;
                :nth-child(2) {
                    margin-left: 0px;
                }
            }

            .accordion-item {
                .item-header {
                    div {
                        min-width: 50px;
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 770px) { 
    .financial-wrapper {
        margin-right: 0px;

        .btns-wapper {
            width: 130%;
        }

        .my-orders-header {
            padding: 5px 20px;

            .my-orders-header-i p {
                font-size: 14px;
            }

            a {
                font-size: 13px;
            }
        }

        .status-icon-filter {
            flex-direction: row !important;
            align-items: center;
            justify-content: space-between;
            padding-top: 10px !important;
        }

        .my-orders-search {
            flex-wrap: wrap;
            padding: 15px 15px 0px 15px;
            height: fit-content;
            flex-direction: column;
            font-size: 12px;
            gap: 5px;

            .dates-inputs {
                margin-top: -30px;

                label {
                    font-size: 11px;
                }
            }
            .react-date-picker {
                height: 30px;
            }
            
            select {
                min-width: 200px;
                font-size: 12px;
            }

            input {
                height: 30px;
            }

            input, label {
                font-size: 11px;
            }

            .search-refresh {
                align-self: end;
                svg {
                    height: 14px;
                    margin-bottom: 10px;
                    
                }
            }
        }

        .table-uni {
            overflow-x: auto;
            table {
                width: 145%;
    
                .table-status {
                    min-width: 80px;
                    padding: 4px;
                    div {
                        font-size: 10px !important;
                    }
    
                    .table-status-icon {
                        width: 18px;
                    }
                }
            }
    
            tbody {
                font-family: inherit;
                height: fit-content !important;
                min-height: 300px;
                overflow: hidden;
                max-height: fit-content;
            }
    
            tbody tr {
                &:nth-child(even) {
                    background-color: #F3F5F2;
                }
    
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
    
                .td-check {
                    width: fit-content;
                    margin-right: 0px;
                    margin-left: 10px;
                    
                    div {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
    
                td {
                    width: fit-content;
                    padding-right: 3px;
    
                    .dropdown {
                        .btn {
                            height: 10px;
                            width: 20px;
                            align-items: center;
                            justify-content: center;
                            display: flex;
                            padding: 10px;

                            svg {
                                height: 8px;
                            }
                        }

                        .dropdown-menu.show {
                            span {
                                font-size: 10px;
                            }
                        }
                    }
    
                    .dropdown-toggle::after {
                        display: none;
                    }
                }
            }

            .table-header {
                padding: 0px 5px 0px 0px;
                .check-header {
                    width: fit-content;
                    padding: 5px;
                    margin-right: 1px;
                }

                td {
                    font-size: 9px;
                    width: fit-content;
                }

                font-size: 10px;
                :nth-child(2) {
                    margin-left: 0px;
                }

                .header {
                    display: none;
                }
            }

            tbody {
                font-size: 10px;
            }

            .accordion-item {
                .item-header {
                    align-items: flex-start;
                    padding-left: 15px;
                    gap: 8px;

                    div:first-child {
                        margin-left: 0px;
                    }

                    div {
                        text-align: left;
                        min-width: 50px;
                        font-size: 12px;
                        font-weight: 600;

                        span {
                            font-weight: normal;
                        }
                    }

                    .table-status {
                        div {
                            font-weight: normal !important;
                        }
                    }
                }
            }

            .accordion-body {
                padding: 20px;

                .item-body {
                    label {
                        font-size: 10px
                    }

                    p {
                        font-size: 11px;
                    }

                    .item-body-actions {
                        margin-left: 0px;
                        align-self: flex-end;
                        margin-right: 10px;
                        width: fit-content;

                        button {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 520px) { 
    .dark .financial-wrapper .my-orders-search .react-date-picker .react-date-picker__inputGroup input {
        height: 28px;
    }

    .financial-wrapper {
        .table-uni {
            table {
                .table-status {
                    min-width: 50px;
                    padding: 1px;
                    margin-right: -10px;

                    div {
                        font-size: 8px !important;
                    }
                    .table-status-icon {
                        width: 7px;
                        height: 5px;
                        margin-right: 3px;
                    }
                }
            }

            tbody tr {
                padding-left: 4px;
                .td-check {
                    margin-left: 0px;
                }

                td {
                    font-size: 9px;
                }
            }

            .table-header {
                .form-check-input {
                    width: 10px;
                    height: 10px;
                }
                td {
                    font-size: 8px;
                }
            }
        }
    }
}