@import '../../variables.scss';

.table-uni {
    .table-actions {
        display: flex;
        align-items: center;
        height: 100%;

        .table-icon-disabled {
            background-color: $c-white;
            width: 25px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #BCBCBC;
            border: 1px solid #BCBCBC;
            border-radius: 8px;
            margin-right: 8px;
    
            svg {
                width: 12px;
            }
        }

        .table-icon {
            background-color: $c-primary;
            width: 25px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $c-white;
            border-radius: 8px;
            margin-right: 8px;
            cursor: pointer;
    
            svg {
                width: 12px;
            }
        }
    }

    .table-status {
        display: flex;
        align-items: center;
        padding: 4px 10px;
        width: fit-content;
        font-size: 13px;
        border-radius: 8px;

        .table-status-icon {
            width: 14px;
            height: 14px;
            border-radius: 50px;
            margin-right: 10px;
        }
    }
}

.dark {
    .table-uni {
        .table-status {
            background-color: $c-dark-blue !important;
        }

        .table-actions {
            .table-icon {
                background-color: $c-strong-green;
                color: $c-dark-blue;
            }

            .table-icon-disabled {
                background-color: $c-dark-blue;
            }
        }
    }
}

@media screen and (max-width: $b-sm) {  

    
}