.uni-widget-icon-value { 
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    width: 72px;
    
    .divider {
        width: 50px;
        height: 6px;
        border-radius: 20px;
    }

    .score {
        font-family: 'Montserrat';
        font-weight: 700;
        font-size: 20px;
    }
    
	.legend {
		font-family: 'Montserrat';
		font-weight: 700;
		font-size: 12px;
	}
		
    @media screen and (max-width: 400px) {
        
        img {
            width: 30px;
            height: 30px;
        }

        .divider {
            width: 30px;
        }

        .score {
            font-size: 14px;
        }
    }
}