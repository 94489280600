@import '../variables.scss';

.dark {
    .call-center-new-wrapper {
        background-color: $c-dark-green;        

        .form-title {
            color: $c-white;
        }

        .divider {
            border-bottom: 1px solid $c-dark-blue;
        }

        input, select, textarea {            
            &:focus {
                border-color: $c-green;
                box-shadow: 0 0 0 0.25rem $c-green;
            }
        }

        input {
            color: $c-white;
            background-color: $c-dark-green;
        }

        label {
            color: $c-white !important;
        }

        select {
            color: $c-white;
            background-color: $c-dark-green;
            
            option:hover {
                background-color: $c-green !important;
            }
        }

        textarea {
            background-color: $c-dark-green;
            color: $c-white;
        }

        .call-center-new-header {
            background-color: $c-dark-blue;
        }

        .first-column {
            border-right: 1px solid $c-dark-blue;
        }
    }
}

.call-center-new-wrapper {
    width: 100%;
    max-width: 1310px;
    margin-right: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $c-white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0px 8px 16px 0px rgba(73, 73, 73, 0.06);

    ::placeholder {
        color: #ABABAB;
    }

    input, textarea, select {
        &:focus {
            border-color: $c-light-green-secondary;
            box-shadow: 0 0 0 0.25rem $c-light-green-secondary;
        }
    }

    select {
        border-radius: 8px;
        font-size: 14px;
        color: $c-black;
        width: 305px;
        
        option:hover {
            background-color: $c-green !important;
        }
    }

    label {
        font-size: 14px;
        font-weight: 500;
        color: #666666;
    }

    .call-center-new-header {
        background-color: #065E39;
        height: 54px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 40px;

        .call-center-new-header-i {
            color: $c-strong-green;
            border-radius: 10px 10px 0px 0px;
            display: flex;
            align-items: center;

            img {
                width: 60px;
                margin-top: 5px;
            }

            p {
                font-weight: 600;
                font-size: 18px;
                font-family: 'Montserrat';
                margin: 0px;
                margin-left: 40px;
                width: 70px;
                line-height: 18px;
            }
        }        
    }    

    .form-title {
        color: #444444;
        font-size: 20px;
        font-weight: 600;
        font-family: 'Montserrat';
    }

    .form-control {
        font-size: 14px;
    }

    .body-wrapper {
        padding: 30px 52px;
    }

    .divider {
        border-bottom: 1px solid #E4ECF4;
        width: 100%;
    }
    
    .first-column {
        padding-top: 31px;
    }

    .message {
        margin-bottom: 1rem !important;
    }

    .buttons-wrapper {
        display: flex;
        justify-content: end;
        margin-top: 63px;
    }

    @media screen and (min-width: 768px) {
        .first-column {
            border-right: 1px solid #E4ECF4;
            padding-right: 40px;
        }
    
        .second-column {
            padding-left: 40px;
        }

        .first-column, .second-column {
            padding-top: 31px;
        }

        .message {
            margin-bottom: 0 !important;
        }
    }
}