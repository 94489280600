@import '../../variables.scss';

.uni-modal-customer-survey { 
    .dark-modal-content {
        background-color: $c-dark-green;
        color: $c-white;

        .btn-close {
            color: $c-white;
        }

        textarea.form-control {
            background-color: $c-dark-green;
            color: $c-white;
        }
    }

    .modal-content {
        padding-bottom: 20px;
    }

    .rating-score {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 32px;

        .face {
            display: flex;
            flex-direction: column;
            gap: 8px;
            cursor: pointer;
            
            .divider {
                width: 50px;
                height: 6px;
                border-radius: 20px;
            }
            .score {
                font-family: 'Montserrat';
                font-weight: 700;
                font-size: 20px;
            }
        }
    }

    .progress-bar {
        flex-direction: row;
        gap: 6px;
        margin: 20px 20px 0px 20px;

        .bar {
            border-radius: 20px;
            background-color: #00FF3C;
            width: 42px;
            height: 6px;
        }

        .dot {
            border-radius: 20px;
            background-color: #D9D9D9;
            width: 6px;
            height: 6px;
        }
    }

	.progress-bar-count {
		margin: 10px;
		font-size: 12px;
		font-weight: 700;
	}
	
    .comments {
		margin-bottom: 20px;
		
		.form-label {
            display: flex;			
        }		
    }

    .buttons-group {
        display: flex;
        justify-content: center;
        gap: 30px;

        .btn {
            width: 191px;
            height: 47px;
            border-radius: 20px;
        }

        &.finish {
            margin-top: 40px;
        }

    }	
	
	@media screen and (min-width: $b-sm) {
		.modal-content {
			width: 640px;
		}
	}
}